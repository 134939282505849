@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?w1v59t');
  src:  url('fonts/icomoon.eot?w1v59t#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?w1v59t') format('truetype'),
    url('fonts/icomoon.woff?w1v59t') format('woff'),
    url('fonts/icomoon.svg?w1v59t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
  content: "\e92d";
  color: #1473b3;
}
.icon-arrow:before {
  content: "\e92b";
  color: #003e69;
}
.icon-close-delete:before {
  content: "\e92c";
  color: #28a228;
}
.icon-ico-0:before {
  content: "\e933";
  color: #999;
}
.icon-ico-1:before {
  content: "\e934";
  color: #999;
}
.icon-ico-2:before {
  content: "\e935";
  color: #999;
}
.icon-ico-3:before {
  content: "\e936";
  color: #999;
}
.icon-ico-4:before {
  content: "\e937";
  color: #999;
}
.icon-ico-6:before {
  content: "\e938";
  color: #999;
}
.icon-ico-7:before {
  content: "\e939";
  color: #999;
}
.icon-ico-8:before {
  content: "\e93a";
  color: #999;
}
.icon-ico-9:before {
  content: "\e93b";
  color: #999;
}
.icon-ico-10:before {
  content: "\e93c";
  color: #999;
}
.icon-ico-11:before {
  content: "\e93d";
  color: #999;
}
.icon-ico-12:before {
  content: "\e93f";
  color: #999;
}
.icon-ico-13:before {
  content: "\e940";
  color: #999;
}
.icon-info:before {
  content: "\e941";
  color: #28a228;
}
.icon-mobile-gps:before {
  content: "\e942";
  color: #28a228;
}
.icon-mobile-link:before {
  content: "\e943";
  color: #008000;
}
.icon-pin-big-dark .path1:before {
  content: "\e944";
  color: rgb(75, 75, 75);
}
.icon-pin-big-dark .path2:before {
  content: "\e945";
  margin-left: -0.75em;
  color: rgb(0, 162, 6);
}
.icon-pin-big-green .path1:before {
  content: "\e946";
  color: rgb(0, 162, 6);
}
.icon-pin-big-green .path2:before {
  content: "\e947";
  margin-left: -0.75em;
  color: rgb(255, 255, 255);
}
.icon-pin-number:before {
  content: "\e948";
  color: #00a206;
}
.icon-list:before {
  content: "\e929";
}
.icon-grid:before {
  content: "\e92a";
}
.icon-pointer:before {
  content: "\e928";
}
.icon-mobile:before {
  content: "\e958";
}
.icon-paragraph-left:before {
  content: "\ea77";
}
.icon-zoom-in:before {
  content: "\e923";
}
.icon-zoom-out:before {
  content: "\e924";
}
.icon-rtf:before {
  content: "\e925";
}
.icon-doc1:before {
  content: "\e926";
}
.icon-file-transfer:before {
  content: "\e927";
}
.icon-docx:before {
  content: "\e920";
}
.icon-xls:before {
  content: "\e921";
}
.icon-ppt:before {
  content: "\e922";
}
.icon-akce:before {
  content: "\e91a";
}
.icon-fotky:before {
  content: "\e91b";
}
.icon-kontakt:before {
  content: "\e91c";
}
.icon-o_nas:before {
  content: "\e91d";
}
.icon-upozorneni:before {
  content: "\e91e";
}
.icon-sluzby:before {
  content: "\e91f";
}
.icon-360:before {
  content: "\e900";
}
.icon-arrow-big:before {
  content: "\e901";
  color: #f8b620;
}
.icon-arrow-small:before {
  content: "\e902";
  color: #f8b620;
}
.icon-play:before {
  content: "\e903";
  color: #f8b620;
}
.icon-zastupni-ikona:before {
  content: "\e904";
  color: #f8b620;
}
.icon-man:before {
  content: "\e905";
}
.icon-woman:before {
  content: "\e906";
}
.icon-ze_zivota_domova:before {
  content: "\e907";
}
.icon-service:before {
  content: "\e908";
}
.icon-past_event:before {
  content: "\e909";
}
.icon-o_nas1:before {
  content: "\e90a";
}
.icon-notification:before {
  content: "\e90b";
}
.icon-kontakt1:before {
  content: "\e90c";
}
.icon-homepage:before {
  content: "\e90d";
}
.icon-event:before {
  content: "\e90e";
}
.icon-googleplus:before {
  content: "\e90f";
}
.icon-instagram:before {
  content: "\e910";
}
.icon-facebook:before {
  content: "\e911";
}
.icon-linkedin:before {
  content: "\e912";
}
.icon-pinterest:before {
  content: "\e913";
}
.icon-twitter:before {
  content: "\e914";
}
.icon-vimeo:before {
  content: "\e915";
}
.icon-youtube:before {
  content: "\e916";
}
.icon-doc:before {
  content: "\e917";
}
.icon-jpg:before {
  content: "\e919";
}
.icon-pdf:before {
  content: "\e918";
}
