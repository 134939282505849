@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/themes.scss";
@import "modules/pux-slider.scss";
@import "_variables.scss";


.pux-infinite-repeater-options-inner-load-more{
  padding:5rem 0 0 0;
}

.map-box{
  position: relative;
  width:100%;
  text-align: center;
  &-name{
    padding: 1.5rem 3rem;
    @include border-radius(6rem);
    display:inline-block;
    position: relative;
    z-index: 888;
    @include transform(translate(0, 50%));
  }
  #map-canvas{
    width: 100%;
    height:50rem;
  }
}

.map-points{
  display: none;
}

.marker-detail{
  display: none;
}


.marker-detail-close{
  opacity: 1 !important;
  img{
    display: none !important;
  }
  &:before{
    content:"\03a7";
    font-family: $font-family !important;
  }
}



.top-image {
  display: none;
  position: relative;
  @include media(md) {
    display: block;
    h1 {
      color: $base-white;
      position: relative;
      z-index: 2;
      padding: 0;
    }
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#2e2e2e,0.3);
  }
}

.service-item{
  box-shadow: 0 20px 70px rgba(41, 41, 41, 0.07);
  background: $base-white;
  height: 100%;
  width: 100%;
  border-radius: 25px;
  overflow: hidden;
  &-image{
    width: 100%;
    height: 260px;
    position: relative;
    @include media(md){
      height: 26rem;
    }
    &-bgr{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-position: center!important;
      background-size: cover!important;
      background-repeat: no-repeat!important;
    }
  }
  &-content{
    text-align: center;
    padding: 2rem 6rem 4rem;
    @include media(lg) {
      padding: 2rem 2rem 4rem;
    }
    @include media(xxl) {
      padding: 2rem 6rem 4rem;
    }
    .h3, h3 {
      padding-bottom: 1.5rem;
      display: block;
      @include pux-scale-with-min('font-size', 26px, 20px);
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    &-btn{
      padding: 3rem 0 3.5rem;
    }
    &-labels{
      li {
        display: inline-block;
        padding: 0;
        margin: 0;
      }

      .service-item-label{
        display: inline-block;
        padding: 2px 15px;
        @include pux-static-size ('font-size', 15px);
        @include border-radius(4px);
        margin: 5px;
        border-radius: 13px;
        transition: 0.3s;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
    @include themify($themes) {
      .h3{
        color: themed('theme-color');
        &:hover,
        &:focus {
          color: $base-black;
        }
      }
      .btn-primary{
        background: themed('theme-color');
        color: $base-white;
        &:focus,
        &:hover{
            background: $base-black;
        }
      }
      .service-item-label {
        color: themed('theme-color');
        border: 1px solid themed('theme-color');
        &:hover,
        &:focus {
          background-color: themed('theme-color');
          color: $base-white;
        }
      }
    }
}

.row.services{
  margin: 0 -10px!important;
  [class^="col-"]{
    padding: 10px;
  }
}

.service-title{
  padding-bottom: 6rem;
  h1,h2{
    padding: 0;
  }
}

.service-subtitle {
  padding: 7rem 0 4rem 0;
  @include media(xxs, xs) {
    @include pux-static-size('font-size', 33px);
  }
  @include media(md) {
    padding: 8rem 0;
    margin: 0;
  }
}
.service-container{
  padding: 7rem 0 0;
}

.list-switcher {
  display: none;
  @include media(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-inner {
      display: flex;
  }
  .switcher-list-basic,
  .switcher-list-long {
    background-color: $base-grey;
    padding: 1rem;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;

     &:before {
       color: $base-white;
       font-size: 30px;
       display: inline;
       vertical-align: middle;

     }
  }

  .switcher-list-basic {
    &:before {
      content: '\e92a';
    }

  }
  .switcher-list-long {
    margin-left: 1rem;
    &:before {
      content: '\e929';
    }

  }
}

#map-search-result {
    font-size: 18px;
    > div {
      display: none;
    }
}

@include themify($themes) {
  .switcher-list-basic.active,
  .switcher-list-long.active {
    background: themed('theme-color');
  }
}

.cta-item{
  background: $base-white;
  padding: 4rem;
  margin: 10px 0 10px;
  box-shadow: 0 20px 70px rgba(41, 41, 41, 0.07);
  h1,h2,h3,h4{
    padding-top: 0;
  }
  &-btn{
    padding-top: 3rem;
    text-align: center;
  }
}

.gallery-item.row{
  margin: 3rem -10px;
  [class^="col-"]{
    padding: 10px;
  }
  a{
    display: block;
    position: relative;
    height: 240px;
    @include media(md){
      height: 24rem;
    }
    .gallery-item-image-bgr{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-position: center!important;
      background-size: cover!important;
      background-repeat: no-repeat!important;
    }
  }
}

.homes-filter {
    @include media(sm) {
        margin-bottom: 4.5rem;
    }
    @include media(lg) {
        display: flex;
    }
    &-content {
        @include media(lg) {
            padding: 4rem 4rem 3rem;
            background-color: $base-white;
            flex: 0 0 52rem;
            max-width: 52rem;
            border-radius: 2.5rem 0 0 2.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 58rem;
        }
        h3 {
            color: $base-black;
            margin-top: 0;
            padding-top: 0;
            display: none;
            @include media(lg) {
                display: inline-block;
            }
        }
        &-top {
            @include media(xxs, md) {
                display: flex;
                flex-direction: column;
            }
        }
        &-bottom {
            margin-top: 3rem;
            display: none;
            @include media(lg) {
                border-top: 2px solid #ebebeb;
                display: flex;
                flex-wrap: wrap;
            }
            & > span {
                @include media(lg) {
                    flex: 0 1 50%;
                    max-width: 100%;
                }
            }
        }
    }
    &-map {
        @include media(lg) {
        	  flex: 1 1 100%;
            max-width: calc(100% - 52rem);
        }
    }
}

.filter-btn {
    width: 100%;
    margin-top: 10px;
    @include pux-scale-with-min("height", 60px, 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-right: 15px;
    @include media(lg) {
        margin-top: 15px;
    }
    @include media(lg) {
        display: none;
    }
    &:after {
        content: "\e901";
        position: absolute;
        color: $base-white;
        font-size: 10px;
        top: 50%;
        right: 22px;
        transform: translateY(-50%);
    }
    &.open {
        &:after {
            transform: rotate(180deg) translateY(50%);
        }
    }
}

.homes-filter-zip,
.homes-filter-address {
    position: relative;
    margin-top: 2rem;
    @include media(xxs, md) {
        order: 1;
    }
    input {
        @include pux-scale-with-min("height", 60px, 50px);
        border: 2px solid $base-grey;
        border-radius: 30px;
        width: 100%;
        font-size: 16px;
        @include transition;
        padding: 0.6rem 2.6rem;
        padding-right: 75px;
        @include pux-scale-from("font-size", xl, 18px);
        @include media(lg) {
            width: 100%;
            padding-right: calc(100% - 160px);
        }
        @include media(xl) {
            padding-right: calc(100% - 200px);
        }
        &:focus {
            outline: 0;
        }
    }
    .btn {
        display: none !important;
        position: absolute;
        top: 0;
        right: 0;
        line-height: 1;
        border-radius: 30px;
        padding: 2.5rem 4rem;
        color: $base-white;
        @include media(lg) {
            padding: 2.5rem 2.6rem;
            display: inline-flex !important;
        }
        @include media(xl) {
            padding: 2.5rem 4rem;
        }
    }
    &:before {
        content: "\e942";
        position: absolute;
        top: 50%;
        right: 3rem;
        font-size: 27px;
        color: $main-color;
        transform: translateY(-50%);
        @include media(lg) {
            display: none;
        }
    }
}

.homes-filter-zip {
    display: none;
    @include media(lg) {
        display: block;
    }
}

.homes-filter-address {
    @include media(lg) {
        display: none;
    }
}

@include themify($themes) {
  .homes-filter-zip {
      input {
          &:focus {
              border-color: themed('theme-color');
          }
      }
      .btn {
          background-color: themed('theme-color');
          &:hover,
          &:focus {
              background-color: darkenthemed('theme-color');
          }
      }
      &:before {
          color: themed('theme-color');
      }
  }
}

@include themify($themes) {
  .homes-filter-restart {
      display: none;
      font-size: 16px;
      font-weight: 700;
      margin-top: 2.4rem;
      background: transparent;
      border: none;
      cursor: pointer;
      color: themed('theme-color');
      @include media(lg) {
          display: inline-flex;
          align-items: center;
      }
      &:before {
          content: "\e92c";
          margin-right: 10px;
          font-size: 14px;
          color: themed('theme-color');
          @include media(lg) {
              margin-right: 1rem;
          }
      }
      &:hover,
      &:focus {
          color: lighten(themed('theme-color'), 5);
          &:before {
              color: lighten(themed('theme-color'), 5);
          }
      }
  }
}

.homes-map-description {
    display: inline-flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    margin-top: 20px;
    padding-right: 2rem;
    white-space: nowrap;
    color: #4c4c4c;
    @include pux-scale-from("font-size", xl, 18px);
    @include media(lg) {
        margin-top: 2.5rem;
    }
    img {
        width: 17px;
        @include pux-scale-from("width", xl, 18px);
        margin-right: 10px;
        @include media(lg) {
            margin-right: 1rem;
        }
    }
    &:last-child {
      img {
        @include pux-scale-from("width", xl, 14px);
      }
    }
}

@include themify($themes) {
  .information-tooltip {
      display: inline-flex;
      align-items: center;
      margin-left: 10px;
      width: 20px;
      height: 20px;
      position: relative;
      vertical-align: text-top;
      &:before {
          content: "\e941";
          display: inline-block;
          color: themed('theme-color');
          font-size: 20px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
      }
  }
}

.homes-filter-labels {
  @include media(xxs, md) {
      order: 2;
  }
  &-inner {
      @include media(xxs, md) {
          order: 2;
      }
      .filter-label {
          @include media(xxs, md) {
              display: none;
          }
      }
      &.open {
          @include media(xxs, md) {
              order: 2;
              background-color: $base-white;
              border-radius: 20px;
              margin-top: 10px;
              padding: 15px;
              padding-top: 25px;
          }
          @include media(md, md) {
              margin-top: 15px;
              padding: 20px;
              padding-top: 35px;
          }
          .filter-label {
              @include media(xxs, md) {
                  display: flex;
              }
          }
      }
  }
  input[type="checkbox"]{
      //display:none;
      position: absolute;
      top: 2px;
      left: 2px;
      & +label{
          position:relative;
          padding:0 0 2rem 30px;
          margin:0;
          font-size: 16px;
          font-weight: 700;
          display: inline-block;
          cursor: pointer;
          @include pux-scale-from("font-size", xl, 16px);
          &:before{
              content:"";
              width:20px;
              height:20px;
              display:block;
              position:absolute;
              left:0;
              top:1px;
              background-color: #ebebeb;
              @include transition;
          }
          &:after{
              content:"\e92d";
              font-size: 9px;
              display:block;
              left:5px;
              top:7px;
              position:absolute;
              @include opacity(0);
              @include transition;
          }
      }
      &:checked{
          & +label{
              &:after{
                  @include opacity(1);
              }
          }
      }
  }
}

@include themify($themes) {
  input[type="checkbox"]{
    & +label{
      color: themed('theme-color');
      &:after {
          color: themed('theme-color');
      }
      &:hover,
      &:focus {
          color: lighten(themed('theme-color'), 5);
      }
    }

    &:focus {
      &+label {
        &:before {
          border: 2px solid themed('theme-color');
        }
      }
    }
  }
}

.mobile-results {
    order: 3;
    margin-top: 10px;
    @include media(md) {
        margin-top: 15px;
    }
    @include media(lg) {
        display: none;
    }
    .btn {
        width: 100%;
    }
}

@include themify($themes) {
    .btn-colored  {
        background-color: themed('theme-color');
        color: $base-white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @include pux-scale-with-min("height", 60px, 50px);
        &:hover,
        &:focus {
            background-color: lighten(themed('theme-color'), 5);
        }
    }
}

#map-canvas {
    min-height: 400px;
    margin-top: 10px;
    @include media(md) {
        margin-top: 15px;
    }
    @include media(lg) {
        margin-top: 0;
        border-radius: 0 2.5rem 2.5rem 0;
    }
}

div[style*="img/map/pin-number-"] {
    line-height: 38px !important;
}

.gm-style-iw-d {
    color: #5b5b5b;
    font-size: 15px;
    @include pux-scale-from("font-size", xl, 15px);
    h4 {
        font-size: 16px;
        @include pux-scale-from("font-size", xl, 16px);
        font-weight: 700;
    }
}

.gm-style-iw {
    box-shadow: none !important;
    max-width: 300px !important;
    @include media(xxs, md) {
        padding: 0 !important;
        max-height: 80vh !important;
    }
    @include media(lg) {
        max-width: 36rem !important;
    }
    .gm-style-iw-d {
        @include media(xxs, md) {
          max-height: 80vh !important;
          overflow: auto !important;
        }
    }
    .gm-ui-hover-effect {
        top: 4px !important;
        right: 0 !important;
        img {
            display: none !important;
        }
        @include media(xxs, md) {
            background-color: $base-white !important;
            opacity: 1 !important;
        }
        &:before {
            padding: 8px;
            content: "\e92c";
            font-size: 13px;
        }
        span{
          display: none !important;
        }
    }
    .marker-detail-image {
        background-size: cover;
        padding-top: 45%;
        @include media(lg) {
            display: none;
        }
    }
    .marker-detail-link {
        @include media(xxs, md) {
            padding: 0 15px;
            margin-top: 10px;
            display: inline-block;
        }
        h4 {
            padding: 0;
            margin: 0;
        }
    }
    .marker-detail-content,
    .marker-detail-phone,
    .marker-detail-labels {
        margin-top: 10px;
        @include media(xxs, md) {
            padding: 0 15px;
        }
    }
    .marker-detail-labels {
        @include media(xxs, md) {
            padding-bottom: 15px;
        }
    }
}

@include themify($themes) {
    .gm-style-iw-d  {
        h4 {
            color: themed('theme-color');
        }
    }
    .gm-ui-hover-effect {
        &:before {
            color: themed('theme-color');
        }
    }
}

.big-body {
  .article-detail {
    font-size: 1.9rem;
  }
  .article-detail .perex  {
    font-size: 2.1rem;
  }
}

.article-detail {
  @include pux-scale-with-min('font-size', 18px, 15px);
  .perex {
    @include pux-scale-with-min('font-size', 22px, 18px);
  }
  h1 {
    @include pux-scale-with-min('font-size', 24px, 20px);
  }
}
.articles-box {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}

.article-item-box {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 1rem;
  @include media(md) {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

.article-item {
  display: block;
  background-color: $base-white;
  border-radius: 25px;
  text-align: center;
  padding: 2rem 2.5rem 4rem 2.5rem;
  color: $base-black;
  transition: 0.3s ease all;
  @include media(xxl) {
    padding: 4rem 4.5rem;
  }
  h3 {
    color: $main-color;
    padding: 0 0 1rem 0;
  }
  &-perex {
    @include pux-scale-with-min('font-size', 18px, 15px);
  }
  &:hover,
  &:focus {
    color: $base-white;
    background-color: $main-color;
    text-decoration: none;
    h3 {
      color: $base-white;
    }
  }
  &-date {
    @include pux-static-size("font-size", 14px);
  }
}

.gallery-widget-row {
  margin: 0 -1.5rem;
  [class^="col"] {
    padding: 1.5rem;
  }
}

.detail-photo-item{
  text-align: center;
  &-image {
    position: relative;
    height: 0;
    padding-bottom: 55%;
    width: 100%;
    overflow: hidden;
    text-align: center;
  }
  &-bgr{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    @include transition;
  }
  &-text {
    margin: 1rem 0 2rem 0;
    h4 {
      transition: 0.3s ease all;
    }
  }
  &:focus,
  &:hover{
    text-decoration: none;
    .detail-photo-item-bgr{
      @include transform(scale(1.2));
    }
  }
}


.banners-box-init:not(.slick-initialized) {
  display: flex;
  flex-wrap: wrap;
  .banner-item-box {
    flex: 0 0 100%;
    max-width: 100%;
    @include media(md) {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }
}

.banners-box-init {
  margin: 0 -1rem;
}
.banner-item-box {
 padding: 1rem;
}


.banner-item {
  height: 100%;
  width: 100%;
  text-align: center;
  color: $base-black;
  overflow: hidden;
  @include pux-scale-with-min('font-size', 18px, 15px);
  h3 {
    color: $main-color;
    margin: 3rem 0 1rem 0;
    padding: 0;
  }
  &-image-box {
    position: relative;
    height: 260px;
    overflow: hidden;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $base-white;
    @include media(md) {
      height: 24rem;
    }
    &.dark-background {
      background-color: $footer-color;
    }
    img {
      max-width: 23rem;
      transition: 0.3s ease all;
    }
  }
  &-image {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: 0.3s ease all;
  }
}

a.banner-item {
  &:hover,
  &:focus {
    text-decoration: none;
    color: $base-black;
    .banner-item-image,
    img {
      @include transform(scale(1.1));
    }
  }
}

.testimonial {
  text-align: center;
  margin-top: 10px;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60px, rgba(255,255,255,1) 60px, rgba(255,255,255,1) 100%);
  &-image {
    border-radius: 50%;
    overflow: hidden;
    height: 120px;
    width: 120px;
    margin: 0 auto 4rem auto;
  }
  &-text {
    max-width: 118rem;
    margin: 0 auto;
    padding-bottom: 6rem;
    &-name {
      display: inline-block;
      margin-top: 1.5rem;
    }
  }
}

.title-w-separator {
  position: relative;
  display: block!important;
  padding-top: 1rem!important;
  padding-bottom: 0.5rem!important;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    display: block;
  }
}

@include themify($themes) {
  .title-w-separator:before {
    background: lighten(themed('theme-color'),35);
  }
}

:focus {
  outline: 1px!important;
  outline-offset: 1px!important;
}