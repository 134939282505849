@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,800&subset=latin-ext");
.slick-dots {
  display: flex; }
  .slick-dots li {
    padding: 0;
    margin: 0 10px 0 0; }
    .slick-dots li button {
      width: 3.6rem;
      height: 3px;
      background: #007e41;
      padding: 0;
      border: none;
      font-size: 0;
      outline: 0;
      border-radius: 5px; }
    .slick-dots li:before {
      display: none; }
    .slick-dots li.slick-active button, .slick-dots li.active button {
      background: #007e41; }

.slick-arrow {
  width: 26.66667px;
  height: 26.66667px;
  border: none;
  font-size: 0;
  position: absolute;
  top: 130px;
  z-index: 9999;
  cursor: pointer;
  outline: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  transition: 0.3s;
  background-color: #007e41; }
  @media (min-width: 768px) {
    .slick-arrow {
      width: 40px;
      height: 40px;
      top: 12rem; } }
  .slick-arrow:hover, .slick-arrow:focus {
    background: #00984e; }
  .slick-arrow:before {
    font-size: 9.33333px; }
    @media (min-width: 768px) {
      .slick-arrow:before {
        font-size: 14px; } }
  .slick-arrow.slick-next {
    right: -8px; }
    .slick-arrow.slick-next:before {
      content: ""; }
  .slick-arrow.slick-prev {
    left: -8px;
    transform: rotate(180deg); }
    .slick-arrow.slick-prev:before {
      content: ""; }

.custom-slider {
  display: flex;
  overflow: hidden; }
  .custom-slider .custom-slider-item {
    width: 100%;
    min-width: 100%; }
  .custom-slider.slick-slider {
    display: block;
    overflow: visible; }
    .custom-slider.slick-slider .custom-slider-item {
      min-width: 1px; }

.custom-slider-pager .slick-dots {
  margin: 1.5rem 0;
  justify-content: center; }

@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?w1v59t");
  src: url("fonts/icomoon.eot?w1v59t#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?w1v59t") format("truetype"), url("fonts/icomoon.woff?w1v59t") format("woff"), url("fonts/icomoon.svg?w1v59t#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-check:before {
  content: "\e92d";
  color: #1473b3; }

.icon-arrow:before {
  content: "\e92b";
  color: #003e69; }

.icon-close-delete:before {
  content: "\e92c";
  color: #28a228; }

.icon-ico-0:before {
  content: "\e933";
  color: #999; }

.icon-ico-1:before {
  content: "\e934";
  color: #999; }

.icon-ico-2:before {
  content: "\e935";
  color: #999; }

.icon-ico-3:before {
  content: "\e936";
  color: #999; }

.icon-ico-4:before {
  content: "\e937";
  color: #999; }

.icon-ico-6:before {
  content: "\e938";
  color: #999; }

.icon-ico-7:before {
  content: "\e939";
  color: #999; }

.icon-ico-8:before {
  content: "\e93a";
  color: #999; }

.icon-ico-9:before {
  content: "\e93b";
  color: #999; }

.icon-ico-10:before {
  content: "\e93c";
  color: #999; }

.icon-ico-11:before {
  content: "\e93d";
  color: #999; }

.icon-ico-12:before {
  content: "\e93f";
  color: #999; }

.icon-ico-13:before {
  content: "\e940";
  color: #999; }

.icon-info:before {
  content: "\e941";
  color: #28a228; }

.icon-mobile-gps:before {
  content: "\e942";
  color: #28a228; }

.icon-mobile-link:before {
  content: "\e943";
  color: #008000; }

.icon-pin-big-dark .path1:before {
  content: "\e944";
  color: #4b4b4b; }

.icon-pin-big-dark .path2:before {
  content: "\e945";
  margin-left: -0.75em;
  color: #00a206; }

.icon-pin-big-green .path1:before {
  content: "\e946";
  color: #00a206; }

.icon-pin-big-green .path2:before {
  content: "\e947";
  margin-left: -0.75em;
  color: white; }

.icon-pin-number:before {
  content: "\e948";
  color: #00a206; }

.icon-list:before {
  content: "\e929"; }

.icon-grid:before {
  content: "\e92a"; }

.icon-pointer:before {
  content: "\e928"; }

.icon-mobile:before {
  content: "\e958"; }

.icon-paragraph-left:before {
  content: "\ea77"; }

.icon-zoom-in:before {
  content: "\e923"; }

.icon-zoom-out:before {
  content: "\e924"; }

.icon-rtf:before {
  content: "\e925"; }

.icon-doc1:before {
  content: "\e926"; }

.icon-file-transfer:before {
  content: "\e927"; }

.icon-docx:before {
  content: "\e920"; }

.icon-xls:before {
  content: "\e921"; }

.icon-ppt:before {
  content: "\e922"; }

.icon-akce:before {
  content: "\e91a"; }

.icon-fotky:before {
  content: "\e91b"; }

.icon-kontakt:before {
  content: "\e91c"; }

.icon-o_nas:before {
  content: "\e91d"; }

.icon-upozorneni:before {
  content: "\e91e"; }

.icon-sluzby:before {
  content: "\e91f"; }

.icon-360:before {
  content: "\e900"; }

.icon-arrow-big:before {
  content: "\e901";
  color: #f8b620; }

.icon-arrow-small:before {
  content: "\e902";
  color: #f8b620; }

.icon-play:before {
  content: "\e903";
  color: #f8b620; }

.icon-zastupni-ikona:before {
  content: "\e904";
  color: #f8b620; }

.icon-man:before {
  content: "\e905"; }

.icon-woman:before {
  content: "\e906"; }

.icon-ze_zivota_domova:before {
  content: "\e907"; }

.icon-service:before {
  content: "\e908"; }

.icon-past_event:before {
  content: "\e909"; }

.icon-o_nas1:before {
  content: "\e90a"; }

.icon-notification:before {
  content: "\e90b"; }

.icon-kontakt1:before {
  content: "\e90c"; }

.icon-homepage:before {
  content: "\e90d"; }

.icon-event:before {
  content: "\e90e"; }

.icon-googleplus:before {
  content: "\e90f"; }

.icon-instagram:before {
  content: "\e910"; }

.icon-facebook:before {
  content: "\e911"; }

.icon-linkedin:before {
  content: "\e912"; }

.icon-pinterest:before {
  content: "\e913"; }

.icon-twitter:before {
  content: "\e914"; }

.icon-vimeo:before {
  content: "\e915"; }

.icon-youtube:before {
  content: "\e916"; }

.icon-doc:before {
  content: "\e917"; }

.icon-jpg:before {
  content: "\e919"; }

.icon-pdf:before {
  content: "\e918"; }

.slick-track {
  display: flex; }

.slick-track .slick-slide {
  display: flex;
  height: auto; }

.slider-init .main-slider {
  width: 100%;
  background-repeat: no-repeat !important;
  display: flex;
  position: relative;
  -webkit-background-size: cover !important;
  background-size: cover !important; }
  .slider-init .main-slider:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(46, 46, 46, 0.3); }
    @media (min-width: 768px) {
      .slider-init .main-slider:after {
        background-color: transparent;
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0) 100%); } }
  .slider-init .main-slider .pux-container {
    width: 100%; }
  .slider-init .main-slider-inner {
    width: 100%;
    padding: 0 0 10rem 0;
    min-height: 60rem;
    max-height: calc(100vh - $slider-header-height);
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: flex-start;
    -moz-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    z-index: 2; }
    .slider-init .main-slider-inner-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 75rem; }
      .slider-init .main-slider-inner-content-text {
        color: #fff; }
        .slider-init .main-slider-inner-content-text h1,
        .slider-init .main-slider-inner-content-text h2 {
          color: #fff;
          padding: 5rem 0 3rem; }
      .slider-init .main-slider-inner-content-btn {
        padding: 5rem 0 2rem 0; }
  .slider-init .main-slider.top-center {
    background-position: top center !important; }
  .slider-init .main-slider.top-left {
    background-position: top left !important; }
  .slider-init .main-slider.top-right {
    background-position: top right !important; }
  .slider-init .main-slider.center-center {
    background-position: center center !important; }
  .slider-init .main-slider.bottom-center {
    background-position: bottom center !important; }
  .slider-init .main-slider.bottom-left {
    background-position: bottom left !important; }
  .slider-init .main-slider.bottom-right {
    background-position: bottom right !important; }

.slider-init .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -2rem;
  width: 4rem;
  height: 4rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: none;
  font-size: 0;
  background: transparent;
  outline: 0;
  z-index: 22;
  cursor: pointer; }
  .slider-init .slick-arrow:before {
    font-size: 20px; }
  .slider-init .slick-arrow.slick-prev {
    left: 2rem; }
    .slider-init .slick-arrow.slick-prev:before {
      content: "";
      display: block;
      opacity: 0.5;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity=50);
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg); }
  .slider-init .slick-arrow.slick-next {
    right: 2rem; }
    .slider-init .slick-arrow.slick-next:before {
      content: "";
      display: block;
      -ms-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0.5;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity=50); }

.slider-init .slick-dots {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: flex-end;
  -moz-box-align: flex-end;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  left: 0;
  width: 100%;
  max-width: 172rem;
  margin: auto;
  bottom: 0;
  padding: 0 0 6rem;
  top: auto !important;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .slider-init .slick-dots {
      padding-left: 6rem;
      padding-right: 6rem; } }
  .slider-init .slick-dots li {
    margin: 0;
    padding: 0 2rem 0 0; }
    .slider-init .slick-dots li:before {
      display: none; }
    .slider-init .slick-dots li:last-child {
      padding: 0; }
    .slider-init .slick-dots li button {
      font-size: 0;
      outline: 0;
      width: 6rem;
      height: 6rem;
      border: none;
      background: #fff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      padding: 0;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      opacity: 0.3;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
      filter: alpha(opacity=30);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      cursor: pointer; }
      @media (min-width: 480px) {
        .slider-init .slick-dots li button {
          width: 5.45455rem; } }
      @media (min-width: 545px) {
        .slider-init .slick-dots li button {
          width: 5rem; } }
      @media (min-width: 768px) {
        .slider-init .slick-dots li button {
          width: 4rem; } }
      @media (min-width: 992px) {
        .slider-init .slick-dots li button {
          width: 3.75rem; } }
      @media (min-width: 1200px) {
        .slider-init .slick-dots li button {
          width: 3.33333rem; } }
      @media (min-width: 1400px) {
        .slider-init .slick-dots li button {
          width: 3rem; } }
      @media (min-width: 480px) {
        .slider-init .slick-dots li button {
          height: 5.45455rem; } }
      @media (min-width: 545px) {
        .slider-init .slick-dots li button {
          height: 5rem; } }
      @media (min-width: 768px) {
        .slider-init .slick-dots li button {
          height: 4rem; } }
      @media (min-width: 992px) {
        .slider-init .slick-dots li button {
          height: 3.75rem; } }
      @media (min-width: 1200px) {
        .slider-init .slick-dots li button {
          height: 3.33333rem; } }
      @media (min-width: 1400px) {
        .slider-init .slick-dots li button {
          height: 3rem; } }
      .slider-init .slick-dots li button:before {
        content: "";
        width: 2rem;
        height: 2rem;
        background: #4c4c4c;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
        @media (min-width: 480px) {
          .slider-init .slick-dots li button:before {
            width: 1.81818rem; } }
        @media (min-width: 545px) {
          .slider-init .slick-dots li button:before {
            width: 1.66667rem; } }
        @media (min-width: 768px) {
          .slider-init .slick-dots li button:before {
            width: 1.33333rem; } }
        @media (min-width: 992px) {
          .slider-init .slick-dots li button:before {
            width: 1.25rem; } }
        @media (min-width: 1200px) {
          .slider-init .slick-dots li button:before {
            width: 1.11111rem; } }
        @media (min-width: 1400px) {
          .slider-init .slick-dots li button:before {
            width: 1rem; } }
        @media (min-width: 480px) {
          .slider-init .slick-dots li button:before {
            height: 1.81818rem; } }
        @media (min-width: 545px) {
          .slider-init .slick-dots li button:before {
            height: 1.66667rem; } }
        @media (min-width: 768px) {
          .slider-init .slick-dots li button:before {
            height: 1.33333rem; } }
        @media (min-width: 992px) {
          .slider-init .slick-dots li button:before {
            height: 1.25rem; } }
        @media (min-width: 1200px) {
          .slider-init .slick-dots li button:before {
            height: 1.11111rem; } }
        @media (min-width: 1400px) {
          .slider-init .slick-dots li button:before {
            height: 1rem; } }
      .slider-init .slick-dots li button:hover {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100);
        background: #fff; }
    .slider-init .slick-dots li.slick-active button {
      background: #fff;
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100); }

.slider-wrapper {
  position: relative;
  display: none; }
  @media (min-width: 768px) {
    .slider-wrapper {
      display: block; } }

.slick-track {
  position: relative; }

.dots-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0; }
  .dots-wrapper .slick-dots {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: flex-end;
    -moz-box-align: flex-end;
    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    left: 0;
    width: 100%;
    max-width: 172rem;
    margin: auto;
    bottom: 0;
    padding: 0 0 6rem;
    top: auto !important;
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 768px) {
      .dots-wrapper .slick-dots {
        padding-left: 6rem;
        padding-right: 6rem; } }
    .dots-wrapper .slick-dots li {
      margin: 0;
      padding: 0 2rem 0 0; }
      .dots-wrapper .slick-dots li:before {
        display: none; }
      .dots-wrapper .slick-dots li:last-child {
        padding: 0; }
      .dots-wrapper .slick-dots li button {
        font-size: 0;
        outline: 0;
        width: 6rem;
        height: 6rem;
        border: none;
        background: #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        padding: 0;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        opacity: 0.3;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
        filter: alpha(opacity=30);
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms;
        cursor: pointer; }
        @media (min-width: 480px) {
          .dots-wrapper .slick-dots li button {
            width: 5.45455rem; } }
        @media (min-width: 545px) {
          .dots-wrapper .slick-dots li button {
            width: 5rem; } }
        @media (min-width: 768px) {
          .dots-wrapper .slick-dots li button {
            width: 4rem; } }
        @media (min-width: 992px) {
          .dots-wrapper .slick-dots li button {
            width: 3.75rem; } }
        @media (min-width: 1200px) {
          .dots-wrapper .slick-dots li button {
            width: 3.33333rem; } }
        @media (min-width: 1400px) {
          .dots-wrapper .slick-dots li button {
            width: 3rem; } }
        @media (min-width: 480px) {
          .dots-wrapper .slick-dots li button {
            height: 5.45455rem; } }
        @media (min-width: 545px) {
          .dots-wrapper .slick-dots li button {
            height: 5rem; } }
        @media (min-width: 768px) {
          .dots-wrapper .slick-dots li button {
            height: 4rem; } }
        @media (min-width: 992px) {
          .dots-wrapper .slick-dots li button {
            height: 3.75rem; } }
        @media (min-width: 1200px) {
          .dots-wrapper .slick-dots li button {
            height: 3.33333rem; } }
        @media (min-width: 1400px) {
          .dots-wrapper .slick-dots li button {
            height: 3rem; } }
        .dots-wrapper .slick-dots li button:before {
          content: "";
          width: 2rem;
          height: 2rem;
          background: #4c4c4c;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          border-radius: 50%; }
          @media (min-width: 480px) {
            .dots-wrapper .slick-dots li button:before {
              width: 1.81818rem; } }
          @media (min-width: 545px) {
            .dots-wrapper .slick-dots li button:before {
              width: 1.66667rem; } }
          @media (min-width: 768px) {
            .dots-wrapper .slick-dots li button:before {
              width: 1.33333rem; } }
          @media (min-width: 992px) {
            .dots-wrapper .slick-dots li button:before {
              width: 1.25rem; } }
          @media (min-width: 1200px) {
            .dots-wrapper .slick-dots li button:before {
              width: 1.11111rem; } }
          @media (min-width: 1400px) {
            .dots-wrapper .slick-dots li button:before {
              width: 1rem; } }
          @media (min-width: 480px) {
            .dots-wrapper .slick-dots li button:before {
              height: 1.81818rem; } }
          @media (min-width: 545px) {
            .dots-wrapper .slick-dots li button:before {
              height: 1.66667rem; } }
          @media (min-width: 768px) {
            .dots-wrapper .slick-dots li button:before {
              height: 1.33333rem; } }
          @media (min-width: 992px) {
            .dots-wrapper .slick-dots li button:before {
              height: 1.25rem; } }
          @media (min-width: 1200px) {
            .dots-wrapper .slick-dots li button:before {
              height: 1.11111rem; } }
          @media (min-width: 1400px) {
            .dots-wrapper .slick-dots li button:before {
              height: 1rem; } }
        .dots-wrapper .slick-dots li button:hover {
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100);
          background: #fff; }
      .dots-wrapper .slick-dots li.slick-active button {
        background: #fff;
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100); }

.slider-placeholder {
  width: 15rem; }

.slider-init {
  height: 100%; }
  .slider-init .slick-list {
    height: 100%; }
    .slider-init .slick-list .slick-track {
      height: 100%; }

.main-slider-inner-content-btn .btn-primary {
  white-space: normal;
  padding: 2rem 3rem; }

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 172rem; }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }
  @media (min-width: 768px) {
    .pux-container {
      padding-left: 6rem;
      padding-right: 6rem; } }
  .pux-container .pux-container {
    padding-left: 0;
    padding-right: 0; }

.pux-container .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 768px) {
    .pux-container .row {
      margin-left: -6rem;
      margin-right: -6rem; } }

.pux-container .col-1, .pux-container .col-2, .pux-container .col-3, .pux-container .col-4, .pux-container .col-5, .pux-container .col-6, .pux-container .col-7, .pux-container .col-8, .pux-container .col-9, .pux-container .col-10, .pux-container .col-11, .pux-container .col-12, .pux-container .col,
.pux-container .col-xs-1, .pux-container .col-xs-2, .pux-container .col-xs-3, .pux-container .col-xs-4, .pux-container .col-xs-5, .pux-container .col-xs-6, .pux-container .col-xs-7, .pux-container .col-xs-8, .pux-container .col-xs-9, .pux-container .col-xs-10, .pux-container .col-xs-11, .pux-container .col-xs-12,
.pux-container .col-auto, .pux-container .col-sm-1, .pux-container .col-sm-2, .pux-container .col-sm-3, .pux-container .col-sm-4, .pux-container .col-sm-5, .pux-container .col-sm-6, .pux-container .col-sm-7, .pux-container .col-sm-8, .pux-container .col-sm-9, .pux-container .col-sm-10, .pux-container .col-sm-11, .pux-container .col-sm-12, .pux-container .col-sm,
.pux-container .col-sm-auto, .pux-container .col-md-1, .pux-container .col-md-2, .pux-container .col-md-3, .pux-container .col-md-4, .pux-container .col-md-5, .pux-container .col-md-6, .pux-container .col-md-7, .pux-container .col-md-8, .pux-container .col-md-9, .pux-container .col-md-10, .pux-container .col-md-11, .pux-container .col-md-12, .pux-container .col-md,
.pux-container .col-md-auto, .pux-container .col-lg-1, .pux-container .col-lg-2, .pux-container .col-lg-3, .pux-container .col-lg-4, .pux-container .col-lg-5, .pux-container .col-lg-6, .pux-container .col-lg-7, .pux-container .col-lg-8, .pux-container .col-lg-9, .pux-container .col-lg-10, .pux-container .col-lg-11, .pux-container .col-lg-12, .pux-container .col-lg,
.pux-container .col-lg-auto, .pux-container .col-xl-1, .pux-container .col-xl-2, .pux-container .col-xl-3, .pux-container .col-xl-4, .pux-container .col-xl-5, .pux-container .col-xl-6, .pux-container .col-xl-7, .pux-container .col-xl-8, .pux-container .col-xl-9, .pux-container .col-xl-10, .pux-container .col-xl-11, .pux-container .col-xl-12, .pux-container .col-xl,
.pux-container .col-xl-auto {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-container .col-1, .pux-container .col-2, .pux-container .col-3, .pux-container .col-4, .pux-container .col-5, .pux-container .col-6, .pux-container .col-7, .pux-container .col-8, .pux-container .col-9, .pux-container .col-10, .pux-container .col-11, .pux-container .col-12, .pux-container .col,
    .pux-container .col-xs-1, .pux-container .col-xs-2, .pux-container .col-xs-3, .pux-container .col-xs-4, .pux-container .col-xs-5, .pux-container .col-xs-6, .pux-container .col-xs-7, .pux-container .col-xs-8, .pux-container .col-xs-9, .pux-container .col-xs-10, .pux-container .col-xs-11, .pux-container .col-xs-12,
    .pux-container .col-auto, .pux-container .col-sm-1, .pux-container .col-sm-2, .pux-container .col-sm-3, .pux-container .col-sm-4, .pux-container .col-sm-5, .pux-container .col-sm-6, .pux-container .col-sm-7, .pux-container .col-sm-8, .pux-container .col-sm-9, .pux-container .col-sm-10, .pux-container .col-sm-11, .pux-container .col-sm-12, .pux-container .col-sm,
    .pux-container .col-sm-auto, .pux-container .col-md-1, .pux-container .col-md-2, .pux-container .col-md-3, .pux-container .col-md-4, .pux-container .col-md-5, .pux-container .col-md-6, .pux-container .col-md-7, .pux-container .col-md-8, .pux-container .col-md-9, .pux-container .col-md-10, .pux-container .col-md-11, .pux-container .col-md-12, .pux-container .col-md,
    .pux-container .col-md-auto, .pux-container .col-lg-1, .pux-container .col-lg-2, .pux-container .col-lg-3, .pux-container .col-lg-4, .pux-container .col-lg-5, .pux-container .col-lg-6, .pux-container .col-lg-7, .pux-container .col-lg-8, .pux-container .col-lg-9, .pux-container .col-lg-10, .pux-container .col-lg-11, .pux-container .col-lg-12, .pux-container .col-lg,
    .pux-container .col-lg-auto, .pux-container .col-xl-1, .pux-container .col-xl-2, .pux-container .col-xl-3, .pux-container .col-xl-4, .pux-container .col-xl-5, .pux-container .col-xl-6, .pux-container .col-xl-7, .pux-container .col-xl-8, .pux-container .col-xl-9, .pux-container .col-xl-10, .pux-container .col-xl-11, .pux-container .col-xl-12, .pux-container .col-xl,
    .pux-container .col-xl-auto {
      padding-left: 6rem;
      padding-right: 6rem; } }

.pux-fluid-container {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 6rem;
      padding-right: 6rem; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 12rem;
      padding-right: 12rem; } }

.small-container {
  max-width: 70rem;
  margin: 0 auto; }

header.scrolled .header-logo a {
  font-size: 18px; }

header.scrolled .header-logo img {
  height: 3.5rem !important; }

.link-full .link-full-social {
  margin-left: 2rem;
  padding-left: 2rem;
  border-left: 1px solid rgba(76, 76, 76, 0.25);
  display: none; }
  @media (min-width: 1400px) {
    .link-full .link-full-social {
      display: flex;
      align-items: center; } }

header {
  position: fixed;
  width: 100%;
  z-index: 99992;
  background-color: #fff;
  height: 70px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  header .header {
    height: 70px; }
  header.scrolled, header.scrolled .header {
    height: 60px; }
  @media (min-width: 1200px) {
    header {
      height: 10rem; }
      header .header {
        height: 10rem; }
      header.scrolled {
        height: 7rem; }
        header.scrolled .header {
          height: 7rem; } }
  header .header {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    transition: 0.3s ease all;
    width: 100%; }
    header .header-logo {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      line-height: 1.2; }
      header .header-logo a {
        display: inline-block;
        font-size: 20px;
        text-transform: uppercase;
        color: #4c4c4c; }
        header .header-logo a.link-full {
          display: none; }
          @media (min-width: 768px) {
            header .header-logo a.link-full {
              display: flex; } }
        header .header-logo a.link-respo {
          display: inline-block; }
          @media (min-width: 768px) {
            header .header-logo a.link-respo {
              display: none; } }
        header .header-logo a:hover, header .header-logo a:focus {
          text-decoration: none; }
        header .header-logo a span {
          font-weight: 700; }
        header .header-logo a img {
          height: 4.5rem !important;
          max-width: none;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          margin-left: 1rem; }
          @media (min-width: 768px) {
            header .header-logo a img {
              border-right: 1px solid rgba(76, 76, 76, 0.25);
              padding-right: 2rem;
              margin-right: 2rem; } }
    header .header-menu {
      display: none;
      position: absolute;
      z-index: 77;
      left: 0;
      top: 100%;
      width: 100%;
      max-height: -webkit-calc(100vh - 5rem);
      max-height: -moz-calc(100vh - 5rem);
      max-height: calc(100vh - 5rem);
      transition: 0.3s ease all;
      background-color: #fff; }
      @media (min-width: 1200px) {
        header .header-menu {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          position: static;
          background: none;
          width: auto;
          height: 100%;
          overflow: hidden; } }
      header .header-menu ul {
        margin: 0;
        padding: 0;
        list-style: none; }
        @media (min-width: 1200px) {
          header .header-menu ul {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            justify-content: flex-end;
            width: 100%; } }
        header .header-menu ul li {
          display: block;
          position: relative;
          margin: 2rem 0; }
          @media (min-width: 1200px) {
            header .header-menu ul li {
              margin: 0;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;
              -webkit-box-pack: center;
              -moz-box-pack: center;
              -ms-flex-pack: center;
              -webkit-justify-content: center;
              justify-content: center; } }
          @media (min-width: 1500px) {
            header .header-menu ul li {
              padding: 0 1rem; } }
          @media (min-width: 1200px) {
            header .header-menu ul li:before,
            header .header-menu ul li a:before {
              content: '';
              opacity: 0;
              transition: 0.3s ease opacity; } }
          header .header-menu ul li.active:before,
          header .header-menu ul li a:hover:before {
            content: '';
            position: absolute;
            bottom: 50%;
            left: 15px;
            transform: translate(-50%, 50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 11px 0 11px 18px;
            display: none; }
            @media (min-width: 1400px) {
              header .header-menu ul li.active:before,
              header .header-menu ul li a:hover:before {
                display: block; } }
          @media (min-width: 1200px) {
            header .header-menu ul li.active:before,
            header .header-menu ul li a:hover:before {
              content: '';
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 0);
              border-width: 0 18px 18px 18px;
              opacity: 1; } }
          header .header-menu ul li.blue a {
            color: #007ea8; }
          header .header-menu ul li.blue.active:before,
          header .header-menu ul li.blue a:hover:before {
            border-color: transparent transparent transparent #007ea8; }
            @media (min-width: 1200px) {
              header .header-menu ul li.blue.active:before,
              header .header-menu ul li.blue a:hover:before {
                border-color: transparent transparent #007ea8 transparent; } }
          header .header-menu ul li.blue a:focus {
            border: 2px solid #007ea8; }
          header .header-menu ul li.green a {
            color: #218721; }
          header .header-menu ul li.green.active:before,
          header .header-menu ul li.green a:hover:before {
            border-color: transparent transparent transparent #218721; }
            @media (min-width: 1200px) {
              header .header-menu ul li.green.active:before,
              header .header-menu ul li.green a:hover:before {
                border-color: transparent transparent #218721 transparent; } }
          header .header-menu ul li.green a:focus {
            border: 2px solid #218721; }
          header .header-menu ul li.orange a {
            color: #ca4c1e; }
          header .header-menu ul li.orange.active:before,
          header .header-menu ul li.orange a:hover:before {
            border-color: transparent transparent transparent #ca4c1e; }
            @media (min-width: 1200px) {
              header .header-menu ul li.orange.active:before,
              header .header-menu ul li.orange a:hover:before {
                border-color: transparent transparent #ca4c1e transparent; } }
          header .header-menu ul li.orange a:focus {
            border: 2px solid #ca4c1e; }
          header .header-menu ul li span:not(.menu-openner),
          header .header-menu ul li a {
            display: block;
            padding: 1.5rem 1rem;
            font-size: 20px;
            font-weight: 700;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            -webkit-box-pack: center;
            -moz-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            line-height: 23px; }
            @media (min-width: 1200px) {
              header .header-menu ul li span:not(.menu-openner),
              header .header-menu ul li a {
                padding: 1.5rem 1.5rem; } }
            header .header-menu ul li span:not(.menu-openner):focus, header .header-menu ul li span:not(.menu-openner):hover,
            header .header-menu ul li a:focus,
            header .header-menu ul li a:hover {
              text-decoration: none; }
            @media (min-width: 1200px) {
              header .header-menu ul li span:not(.menu-openner),
              header .header-menu ul li a {
                text-align: center; } }
          header .header-menu ul li span:not(.menu-openner) {
            cursor: default; }
          header .header-menu ul li.menu-360 {
            display: flex; }
            header .header-menu ul li.menu-360:before {
              content: "\e900";
              align-self: center;
              width: 30px;
              margin-left: 10px; }
              @media (min-width: 1200px) {
                header .header-menu ul li.menu-360:before {
                  width: auto;
                  margin-left: 3px;
                  display: none; } }
              @media (min-width: 1200px) {
                header .header-menu ul li.menu-360:before {
                  display: block; } }
          header .header-menu ul li ul {
            display: none;
            width: 100%;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            visibility: visible;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
            @media (min-width: 1200px) {
              header .header-menu ul li ul {
                position: absolute;
                top: 100%;
                width: 25rem;
                left: 50%;
                right: 50%;
                transform: translateX(-11.5rem);
                visibility: hidden;
                opacity: 0;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: alpha(opacity=0);
                display: block;
                padding: 1rem 0rem; } }
            @media (min-width: 1200px) {
              header .header-menu ul li ul li a {
                padding: 1rem 1.5rem; } }
          header .header-menu ul li:hover > ul {
            visibility: visible;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100); }
          header .header-menu ul li.open .menu-openner:after {
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
          header .header-menu ul li.open ul {
            display: block; }
          @media (min-width: 1200px) {
            header .header-menu ul li.has-ul {
              margin: 0 0.5rem 0 0; } }
          @media (min-width: 1200px) {
            header .header-menu ul li.has-ul {
              margin: 0 1rem; } }
          @media (min-width: 1200px) {
            header .header-menu ul li.has-ul span:not(.menu-openner),
            header .header-menu ul li.has-ul a {
              padding: 1.5rem 2rem 1.5rem 1rem; } }
          @media (min-width: 1200px) {
            header .header-menu ul li.has-ul span:not(.menu-openner),
            header .header-menu ul li.has-ul a {
              padding: 1.5rem 2rem 1.5rem 1.5rem; } }
      header .header-menu .menu-openner {
        position: absolute;
        top: 0;
        right: 0;
        width: 6rem;
        height: 6rem; }
        @media (min-width: 1200px) {
          header .header-menu .menu-openner {
            position: absolute;
            width: auto;
            height: auto;
            background: transparent;
            bottom: .5rem;
            right: 1rem; } }
        header .header-menu .menu-openner:after, header .header-menu .menu-openner:before {
          content: "";
          width: 70%;
          height: 2px;
          position: absolute;
          left: 15%;
          top: 50%;
          margin-top: -1px; }
          @media (min-width: 1200px) {
            header .header-menu .menu-openner:after, header .header-menu .menu-openner:before {
              content: "\e902";
              background: transparent;
              height: auto;
              width: auto;
              font-size: 6px; } }
        header .header-menu .menu-openner:after {
          -ms-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          @media (min-width: 1200px) {
            header .header-menu .menu-openner:after {
              display: none; } }
    header .header-hamburger {
      background-color: #007e41;
      display: flex;
      align-items: center;
      padding: 0.5rem 3rem;
      border-radius: 50px;
      cursor: pointer; }
      @media (min-width: 1200px) {
        header .header-hamburger {
          display: none; } }
      header .header-hamburger span {
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-weight: 700; }
      header .header-hamburger .open-menu {
        width: 15px;
        height: 15px;
        position: relative;
        display: block;
        margin-right: 1rem; }
        header .header-hamburger .open-menu span {
          width: 10px;
          display: block;
          background: #fff;
          position: absolute;
          left: 0.5rem;
          height: 2px;
          margin-top: -1.5px;
          top: 50%;
          border-radius: 50px;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          header .header-hamburger .open-menu span:before, header .header-hamburger .open-menu span:after {
            content: "";
            width: 10px;
            background-color: #fff;
            border-radius: 50px;
            left: 0;
            height: 2px;
            position: absolute;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
          header .header-hamburger .open-menu span:before {
            top: -4px; }
          header .header-hamburger .open-menu span:after {
            bottom: -4px; }
    header .header.open .header-hamburger .open-menu span {
      background: transparent; }
      header .header.open .header-hamburger .open-menu span:before {
        top: 0;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      header .header.open .header-hamburger .open-menu span:after {
        top: 0;
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    header .header.open .header-menu {
      display: block; }
      @media (min-width: 1200px) {
        header .header.open .header-menu {
          display: flex; } }

.EditMode header,
.DesignMode header {
  position: static; }

footer {
  background-color: #003e69;
  color: #fff; }
  @media (min-width: 320px) {
    footer {
      font-size: 3rem; } }
  @media (min-width: 480px) {
    footer {
      font-size: 2.72727rem; } }
  @media (min-width: 545px) {
    footer {
      font-size: 2.5rem; } }
  @media (min-width: 768px) {
    footer {
      font-size: 2rem; } }
  @media (min-width: 992px) {
    footer {
      font-size: 1.875rem; } }
  @media (min-width: 1200px) {
    footer {
      font-size: 1.66667rem; } }
  @media (min-width: 1400px) {
    footer {
      font-size: 1.6rem; } }
  footer ul {
    text-align: left; }
  footer .footer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 5rem 0 0 0; }
    footer .footer a {
      color: #fff; }
    @media (min-width: 320px) {
      footer .footer h4 {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      footer .footer h4 {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      footer .footer h4 {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      footer .footer h4 {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      footer .footer h4 {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      footer .footer h4 {
        font-size: 1.8rem; } }
    footer .footer-column {
      width: 100%;
      padding-bottom: 5rem;
      text-align: center; }
      @media (min-width: 320px) {
        footer .footer-column {
          line-height: 5rem; } }
      @media (min-width: 480px) {
        footer .footer-column {
          line-height: 4.54545rem; } }
      @media (min-width: 545px) {
        footer .footer-column {
          line-height: 4.16667rem; } }
      @media (min-width: 768px) {
        footer .footer-column {
          line-height: 3.33333rem; } }
      @media (min-width: 992px) {
        footer .footer-column {
          line-height: 3.125rem; } }
      @media (min-width: 1200px) {
        footer .footer-column {
          line-height: 3rem; } }
      @media (min-width: 545px) {
        footer .footer-column {
          text-align: left;
          flex: 0 0 33.3333%;
          width: 33.3333%; } }
      @media (min-width: 992px) {
        footer .footer-column {
          text-align: left;
          padding-bottom: 0;
          flex: 0 0 20%;
          width: 20%; } }
    footer .footer .footer-logo {
      display: flex;
      justify-content: center; }
      @media (min-width: 545px) {
        footer .footer .footer-logo {
          width: 50%;
          flex: 0 0 50%;
          margin-top: 4rem;
          text-align: left;
          align-items: flex-start;
          justify-content: flex-start; } }
      @media (min-width: 992px) {
        footer .footer .footer-logo {
          justify-content: flex-end;
          margin-top: 0;
          flex: 0 0 30%;
          width: 30%; } }
      footer .footer .footer-logo a:hover, footer .footer .footer-logo a:focus {
        text-decoration: none; }
      footer .footer .footer-logo a:first-child {
        display: flex;
        align-items: flex-end;
        min-width: 27rem; }
        @media (min-width: 992px) {
          footer .footer .footer-logo a:first-child {
            min-width: 22rem; } }
      footer .footer .footer-logo a:last-child {
        line-height: 1.2;
        text-transform: uppercase;
        max-width: 100px;
        margin-left: 3rem;
        text-align: left; }
        @media (min-width: 320px) {
          footer .footer .footer-logo a:last-child {
            font-size: 2rem; } }
        @media (min-width: 480px) {
          footer .footer .footer-logo a:last-child {
            font-size: 1.81818rem; } }
        @media (min-width: 545px) {
          footer .footer .footer-logo a:last-child {
            font-size: 1.66667rem; } }
        @media (min-width: 768px) {
          footer .footer .footer-logo a:last-child {
            font-size: 1.6rem; } }
        footer .footer .footer-logo a:last-child span {
          font-weight: 700; }
        @media (min-width: 992px) {
          footer .footer .footer-logo a:last-child {
            margin-left: 3rem;
            max-width: 120px; } }
      footer .footer .footer-logo a img {
        max-height: 8rem; }
        @media (min-width: 768px) {
          footer .footer .footer-logo a img {
            max-height: 6.5rem;
            max-width: 22rem;
            margin-top: 1rem; } }
  footer .footer-copyright {
    text-align: center;
    padding: 0 0 5rem 0;
    align-items: center;
    display: flex;
    flex-direction: column-reverse; }
    @media (min-width: 545px) {
      footer .footer-copyright {
        padding: 3rem 0;
        justify-content: space-between;
        text-align: left;
        flex-direction: row; } }
    footer .footer-copyright img {
      width: 130px;
      margin: 3rem 0 6rem 0; }
      @media (min-width: 545px) {
        footer .footer-copyright img {
          margin: 3rem 0 0 0; } }

.is-xxl {
  display: none; }
  @media (min-width: 1400px) {
    .is-xxl {
      display: block; } }

.intro-container {
  position: relative;
  height: 100%; }
  .intro-container .bgr-image {
    height: 100%;
    width: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative; }
    .intro-container .bgr-image:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 75vh;
      background: #000;
      opacity: 0.15;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
      filter: alpha(opacity=15); }
  .intro-container .pux-container {
    position: relative; }
    .intro-container .pux-container .intro-title {
      margin: 0;
      padding: 1.5rem 3rem; }
      @media (min-width: 768px) {
        .intro-container .pux-container .intro-title {
          left: 4.5rem; } }

.founder-wrapper {
  position: absolute;
  bottom: -4rem;
  z-index: 5;
  left: 0;
  width: 100%; }
  .founder-wrapper .pux-container {
    text-align: right; }
    .founder-wrapper .pux-container img {
      max-width: 15rem; }

.breadcrumbs {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-top: 1rem; }
  .breadcrumbs .home {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    font-size: 0; }
    .breadcrumbs .home:after {
      content: "\e90d";
      display: block; }
      @media (min-width: 320px) {
        .breadcrumbs .home:after {
          font-size: 2.8rem; } }
      @media (min-width: 480px) {
        .breadcrumbs .home:after {
          font-size: 2.54545rem; } }
      @media (min-width: 545px) {
        .breadcrumbs .home:after {
          font-size: 2.33333rem; } }
      @media (min-width: 768px) {
        .breadcrumbs .home:after {
          font-size: 1.86667rem; } }
      @media (min-width: 992px) {
        .breadcrumbs .home:after {
          font-size: 1.75rem; } }
      @media (min-width: 1200px) {
        .breadcrumbs .home:after {
          font-size: 1.55556rem; } }
      @media (min-width: 1400px) {
        .breadcrumbs .home:after {
          font-size: 1.5rem; } }
    .breadcrumbs .home:hover, .breadcrumbs .home:focus {
      text-decoration: none; }
      .breadcrumbs .home:hover:after, .breadcrumbs .home:focus:after {
        text-decoration: none; }
  .breadcrumbs .breadcrumbs-separator:after {
    position: relative;
    top: 0px;
    display: inline-block;
    content: "\e902";
    vertical-align: middle;
    -webkit-transform: scale(0.25) rotate(270deg);
    -moz-transform: scale(0.25) rotate(270deg);
    -ms-transform: scale(0.25) rotate(270deg);
    -o-transform: scale(0.25) rotate(270deg);
    transform: scale(0.25) rotate(270deg);
    margin: auto .5rem; }

.header-helper {
  height: 8rem; }
  @media (min-width: 1200px) {
    .header-helper {
      height: 7rem; } }

.header-intro {
  padding-top: 1rem;
  padding-bottom: 4rem;
  text-align: center;
  position: relative; }
  .header-intro .bgr-image {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important; }
  .header-intro h1 {
    font-weight: 600;
    padding: 2rem 0 2rem 0;
    margin: 0; }
  .header-intro-bread, .header-intro-title, .header-intro-subtitle {
    position: relative;
    z-index: 3; }
  .header-intro-title .h1 {
    padding: 0;
    margin: 3rem 0 1rem; }
  .header-intro-subtitle {
    font-weight: 700;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 320px) {
      .header-intro-subtitle {
        font-size: 2.6rem; } }
    @media (min-width: 480px) {
      .header-intro-subtitle {
        font-size: 2.36364rem; } }
    @media (min-width: 545px) {
      .header-intro-subtitle {
        font-size: 2.16667rem; } }
    @media (min-width: 768px) {
      .header-intro-subtitle {
        font-size: 1.73333rem; } }
    @media (min-width: 992px) {
      .header-intro-subtitle {
        font-size: 1.625rem; } }
    @media (min-width: 1200px) {
      .header-intro-subtitle {
        font-size: 1.44444rem; } }
    @media (min-width: 1400px) {
      .header-intro-subtitle {
        font-size: 1.3rem; } }
    .header-intro-subtitle:before {
      content: "\e90e";
      margin-right: 1rem; }

.text-container {
  padding: 5rem 0;
  margin: auto;
  max-width: 900px; }

.row .col-xs-12.col-md-6.col-lg-4.preview {
  padding: 7.5px; }
  .row .col-xs-12.col-md-6.col-lg-4.preview a:focus .detail-photo-bgr,
  .row .col-xs-12.col-md-6.col-lg-4.preview a:hover .detail-photo-bgr {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2); }

.contact-container a {
  font-weight: 700; }

.map-container {
  height: 45rem; }
  @media (min-width: 768px) {
    .map-container {
      height: 100%; } }

.text-and-submit {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .text-and-submit .personal-detail-text {
    width: 100%; }
    @media (min-width: 992px) {
      .text-and-submit .personal-detail-text {
        width: 50%; } }
  .text-and-submit .btn {
    margin: 2rem auto; }
    @media (min-width: 992px) {
      .text-and-submit .btn {
        margin: auto 0; } }

.size-switch {
  cursor: pointer;
  position: fixed;
  top: 20vh;
  right: 0;
  text-align: center;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  z-index: 5;
  display: none; }
  @media (min-width: 1200px) {
    .size-switch {
      display: block; } }
  .size-switch .small-switch,
  .size-switch .big-switch {
    border: none;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    text-align: center;
    background-color: #003e69;
    color: #fff;
    padding: 1rem 2.5rem 1rem 1.5rem;
    text-transform: uppercase;
    height: 10rem;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-transform: rotate(-90deg) translateY(-100%);
    -moz-transform: rotate(-90deg) translateY(-100%);
    -ms-transform: rotate(-90deg) translateY(-100%);
    -o-transform: rotate(-90deg) translateY(-100%);
    transform: rotate(-90deg) translateY(-100%);
    transform-origin: top right; }
    @media (min-width: 480px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        height: 9.09091rem; } }
    @media (min-width: 545px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        height: 8.33333rem; } }
    @media (min-width: 768px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        height: 6.66667rem; } }
    @media (min-width: 992px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        height: 6.25rem; } }
    @media (min-width: 1200px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        height: 5.55556rem; } }
    @media (min-width: 1400px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        height: 5rem; } }
    .size-switch .small-switch.active,
    .size-switch .big-switch.active {
      display: none !important; }
    .size-switch .small-switch:hover,
    .size-switch .big-switch:hover {
      background-color: #005c9c; }
    .size-switch .small-switch:focus,
    .size-switch .big-switch:focus {
      background-color: #005c9c;
      border: 2px solid #0398ff; }
  .size-switch .small-switch {
    border-radius: 10px 10px 0 0;
    box-shadow: 1px 1px 6px rgba(76, 76, 76, 0.3);
    position: relative;
    /*&:hover, &:focus{
      &:after{
        @include transform(translateX(-4rem));
      }
    }*/
    /*&:after{
      content: "Zmenšit písmo";
      @include flex-block;
      @include align-items(center);
      @include justify-content(center);
      font-family: $font-family!important;
      position: absolute;
      right: 0;
      width: 25rem;
      z-index: -1;
      color: $base-black;
      background-color: rgba(255, 255, 255, 0.603);
      @include media(md){
        width: 15rem;
      }
      height: 100%;
      @include pux-scale-with-min("font-size", $font-size-small, $min-font-size-small);
      top: 0;
      @include transition(400ms);
      @include transform(translateX(1000px));
    }*/ }
    .size-switch .small-switch:after {
      content: "\e924";
      line-height: 1;
      display: block;
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      padding-bottom: 20px; }
      @media (min-width: 320px) {
        .size-switch .small-switch:after {
          font-size: 3rem; } }
      @media (min-width: 480px) {
        .size-switch .small-switch:after {
          font-size: 2.72727rem; } }
      @media (min-width: 545px) {
        .size-switch .small-switch:after {
          font-size: 2.5rem; } }
      @media (min-width: 768px) {
        .size-switch .small-switch:after {
          font-size: 2.3rem; } }
  .size-switch .big-switch {
    border-radius: 10px 10px 0 0;
    box-shadow: 1px 1px 6px rgba(76, 76, 76, 0.3);
    position: relative;
    /*&:hover, &:focus{
      &:after{
        @include transform(translateX(-4rem));
      }
    }*/
    /*&:after{
      @include flex-block;
      @include align-items(center);
      @include justify-content(center);
      content: "Zvětšit písmo";
      font-family: $font-family!important;
      position: absolute;
      right: 0;
      width: 30rem;
      color: $base-black;
      background-color: rgba(255, 255, 255, 0.603);
      z-index: -1;
      @include media(md){
        width: 20rem;
      }
      height: 100%;
      line-height: 100%;
      @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
      font-weight: 700;
      top: 0;
      @include transition(400ms);
      @include transform(translateX(1000px));
    }*/ }
    .size-switch .big-switch:after {
      content: "\e923";
      line-height: 1;
      display: block;
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      padding-bottom: 20px; }
      @media (min-width: 320px) {
        .size-switch .big-switch:after {
          font-size: 3rem; } }
      @media (min-width: 480px) {
        .size-switch .big-switch:after {
          font-size: 2.72727rem; } }
      @media (min-width: 545px) {
        .size-switch .big-switch:after {
          font-size: 2.5rem; } }
      @media (min-width: 768px) {
        .size-switch .big-switch:after {
          font-size: 2.3rem; } }

.floating-label-text {
  height: 115px !important; }
  @media (min-width: 768px) {
    .floating-label-text {
      height: 150px !important; } }

.floating-label-text,
.floating-label {
  position: relative;
  width: 100%;
  height: 60px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .floating-label-text > .placeholder,
  .floating-label > .placeholder {
    opacity: 1;
    pointer-events: none;
    line-height: 60px;
    transform: translate3d(0, -100%, 0);
    padding: 0;
    display: inline-block;
    position: relative;
    z-index: 2;
    margin: 0;
    font-size: 30px; }
    .floating-label-text > .placeholder.active,
    .floating-label > .placeholder.active {
      opacity: 0; }
  .floating-label-text > label,
  .floating-label > label {
    position: absolute;
    top: 5px;
    left: 15px;
    display: inline-block;
    transition: all .3s ease;
    opacity: 1;
    z-index: 11;
    padding: 0; }
    .floating-label-text > label.is-visible,
    .floating-label > label.is-visible {
      transform: translate3d(0, -15px, 0);
      opacity: 1;
      font-size: 14px; }
  .floating-label-text > input,
  .floating-label > input {
    position: relative;
    z-index: 1;
    float: left;
    width: 100%;
    height: 60px;
    clear: both;
    margin-bottom: 1rem;
    padding: 0;
    background-color: transparent; }
  .floating-label-text.active label,
  .floating-label.active label {
    transform: translate3d(0, -8px, 0);
    opacity: 1;
    font-size: 10px;
    left: 15px; }
    @media (min-width: 768px) {
      .floating-label-text.active label,
      .floating-label.active label {
        transform: translate3d(0, -10px, 0); } }

.is-visible input {
  padding-top: 2.5rem !important;
  padding-left: 15px !important; }
  @media (min-width: 768px) {
    .is-visible input {
      padding-top: 1.25rem !important; } }

.is-visible textarea {
  padding-top: 3rem !important;
  padding-left: 15px; }
  @media (min-width: 768px) {
    .is-visible textarea {
      padding-top: 2rem !important; } }

.select-box {
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem auto 3rem; }

.javascript-hide {
  display: none; }

.pux-infinite-repeater-options-inner-load-more {
  text-align: center; }

.mobile-name {
  display: block !important;
  text-align: center; }
  @media (min-width: 1200px) {
    .mobile-name {
      display: none !important; } }

.desktop-name {
  display: none !important; }
  @media (min-width: 1200px) {
    .desktop-name {
      display: block !important; } }

header.scrolled .header-menu ul li.active:before,
header.scrolled .header-menu ul li a:hover:before {
  bottom: -4px; }

.dots-wrapper .slick-dots {
  justify-content: center; }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.helper-4k {
  max-width: 200rem;
  margin: auto; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.uppercase {
  text-transform: uppercase; }

html {
  font-size: 5.5px; }
  @media (min-width: 480px) {
    html {
      font-size: 6.05px; } }
  @media (min-width: 545px) {
    html {
      font-size: 6.6px; } }
  @media (min-width: 768px) {
    html {
      font-size: 8.25px; } }
  @media (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1400px) {
    html {
      font-size: 10px; } }
  html.big-body {
    font-size: 5.5px; }
    @media (min-width: 480px) {
      html.big-body {
        font-size: 6.05px; } }
    @media (min-width: 545px) {
      html.big-body {
        font-size: 6.6px; } }
    @media (min-width: 768px) {
      html.big-body {
        font-size: 8.25px; } }
    @media (min-width: 992px) {
      html.big-body {
        font-size: 8.8px; } }
    @media (min-width: 1200px) {
      html.big-body {
        font-size: 9.9px; } }
    @media (min-width: 1400px) {
      html.big-body {
        font-size: 11px; } }
    html.big-body .floating-label {
      height: 60px !important; }
      @media (min-width: 768px) {
        html.big-body .floating-label {
          height: 75px !important; } }
    html.big-body .floating-label-text {
      height: 120px !important; }
      @media (min-width: 768px) {
        html.big-body .floating-label-text {
          height: 180px !important; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 120dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    font-size: 5.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 480px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 480px), only screen and (min-resolution: 120dpi) and (min-width: 480px), only screen and (min-resolution: 1.25dppx) and (min-width: 480px) {
    html {
      font-size: 6.05px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 545px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 545px), only screen and (min-resolution: 120dpi) and (min-width: 545px), only screen and (min-resolution: 1.25dppx) and (min-width: 545px) {
    html {
      font-size: 6.6px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 768px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 768px), only screen and (min-resolution: 120dpi) and (min-width: 768px), only screen and (min-resolution: 1.25dppx) and (min-width: 768px) {
    html {
      font-size: 8.25px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 992px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 992px), only screen and (min-resolution: 120dpi) and (min-width: 992px), only screen and (min-resolution: 1.25dppx) and (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 120dpi) and (min-width: 1200px), only screen and (min-resolution: 1.25dppx) and (min-width: 1200px) {
    html {
      font-size: 7.2px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1400px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1400px), only screen and (min-resolution: 120dpi) and (min-width: 1400px), only screen and (min-resolution: 1.25dppx) and (min-width: 1400px) {
    html {
      font-size: 8px; } }

form,
html,
body {
  font-family: "proxima-nova", sans-serif;
  height: 100%;
  color: #4c4c4c;
  font-weight: 300; }
  form strong,
  html strong,
  body strong {
    font-weight: 700; }

@media (min-width: 320px) {
  body {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  body {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  body {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  body {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  body {
    font-size: 1.75rem; } }

@media (min-width: 1200px) {
  body {
    font-size: 1.55556rem; } }

@media (min-width: 1400px) {
  body {
    font-size: 1.5rem; } }

img {
  max-width: 100%;
  height: auto !important; }

@media (min-width: 320px) {
  .pt-5 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-5 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-5 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-5 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-5 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-5 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-5 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-5 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-5 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-5 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-5 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-5 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-5 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-5 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-5 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-5 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-5 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-5 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-5 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-5 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-5 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-5 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-5 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-5 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-5 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-5 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-5 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-5 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-10 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-10 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-10 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-10 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-10 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-10 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-10 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-10 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-10 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-10 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-10 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-10 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-10 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-10 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-10 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-10 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-10 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-10 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-10 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-10 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-10 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-10 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-10 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-10 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-10 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-10 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-10 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-10 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-15 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-15 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-15 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-15 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-15 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-15 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-15 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-15 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-15 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-15 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-15 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-15 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-15 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-15 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-15 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-15 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-15 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-15 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-15 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-15 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-15 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-15 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-15 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-15 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-15 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-15 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-15 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-15 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-20 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-20 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-20 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-20 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-20 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-20 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-20 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-20 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-20 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-20 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-20 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-20 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-20 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-20 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-20 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-20 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-20 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-20 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-20 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-20 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-20 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-20 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-20 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-20 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-20 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-20 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-20 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-20 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-25 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-25 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-25 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-25 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-25 {
    padding-top: 2.5rem; } }

@media (min-width: 320px) {
  .pb-25 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-25 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-25 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-25 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-25 {
    padding-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .mt-25 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-25 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-25 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-25 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-25 {
    margin-top: 2.5rem; } }

@media (min-width: 320px) {
  .mb-25 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-25 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-25 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-25 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-25 {
    margin-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .pt-30 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-30 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-30 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-30 {
    padding-top: 3rem; } }

@media (min-width: 320px) {
  .pb-30 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-30 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-30 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-30 {
    padding-bottom: 3rem; } }

@media (min-width: 320px) {
  .mt-30 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-30 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-30 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-30 {
    margin-top: 3rem; } }

@media (min-width: 320px) {
  .mb-30 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-30 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-30 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-30 {
    margin-bottom: 3rem; } }

@media (min-width: 320px) {
  .pt-35 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-35 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-35 {
    padding-top: 3.5rem; } }

@media (min-width: 320px) {
  .pb-35 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-35 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-35 {
    padding-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .mt-35 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-35 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-35 {
    margin-top: 3.5rem; } }

@media (min-width: 320px) {
  .mb-35 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-35 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-35 {
    margin-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .pt-40 {
    padding-top: 4rem; } }

@media (min-width: 320px) {
  .pb-40 {
    padding-bottom: 4rem; } }

@media (min-width: 320px) {
  .mt-40 {
    margin-top: 4rem; } }

@media (min-width: 320px) {
  .mb-40 {
    margin-bottom: 4rem; } }

@media (min-width: 320px) {
  .pt-45 {
    padding-top: 4.5rem; } }

@media (min-width: 320px) {
  .pb-45 {
    padding-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .mt-45 {
    margin-top: 4.5rem; } }

@media (min-width: 320px) {
  .mb-45 {
    margin-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .pt-50 {
    padding-top: 5rem; } }

@media (min-width: 320px) {
  .pb-50 {
    padding-bottom: 5rem; } }

@media (min-width: 320px) {
  .mt-50 {
    margin-top: 5rem; } }

@media (min-width: 320px) {
  .mb-50 {
    margin-bottom: 5rem; } }

@media (min-width: 320px) {
  .pt-55 {
    padding-top: 5.5rem; } }

@media (min-width: 320px) {
  .pb-55 {
    padding-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .mt-55 {
    margin-top: 5.5rem; } }

@media (min-width: 320px) {
  .mb-55 {
    margin-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .pt-60 {
    padding-top: 6rem; } }

@media (min-width: 320px) {
  .pb-60 {
    padding-bottom: 6rem; } }

@media (min-width: 320px) {
  .mt-60 {
    margin-top: 6rem; } }

@media (min-width: 320px) {
  .mb-60 {
    margin-bottom: 6rem; } }

@media (min-width: 320px) {
  .pt-65 {
    padding-top: 6.5rem; } }

@media (min-width: 320px) {
  .pb-65 {
    padding-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .mt-65 {
    margin-top: 6.5rem; } }

@media (min-width: 320px) {
  .mb-65 {
    margin-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .pt-70 {
    padding-top: 7rem; } }

@media (min-width: 320px) {
  .pb-70 {
    padding-bottom: 7rem; } }

@media (min-width: 320px) {
  .mt-70 {
    margin-top: 7rem; } }

@media (min-width: 320px) {
  .mb-70 {
    margin-bottom: 7rem; } }

@media (min-width: 320px) {
  .pt-75 {
    padding-top: 7.5rem; } }

@media (min-width: 320px) {
  .pb-75 {
    padding-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .mt-75 {
    margin-top: 7.5rem; } }

@media (min-width: 320px) {
  .mb-75 {
    margin-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .pt-80 {
    padding-top: 8rem; } }

@media (min-width: 320px) {
  .pb-80 {
    padding-bottom: 8rem; } }

@media (min-width: 320px) {
  .mt-80 {
    margin-top: 8rem; } }

@media (min-width: 320px) {
  .mb-80 {
    margin-bottom: 8rem; } }

@media (min-width: 320px) {
  .pt-85 {
    padding-top: 8.5rem; } }

@media (min-width: 320px) {
  .pb-85 {
    padding-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .mt-85 {
    margin-top: 8.5rem; } }

@media (min-width: 320px) {
  .mb-85 {
    margin-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .pt-90 {
    padding-top: 9rem; } }

@media (min-width: 320px) {
  .pb-90 {
    padding-bottom: 9rem; } }

@media (min-width: 320px) {
  .mt-90 {
    margin-top: 9rem; } }

@media (min-width: 320px) {
  .mb-90 {
    margin-bottom: 9rem; } }

@media (min-width: 320px) {
  .pt-95 {
    padding-top: 9.5rem; } }

@media (min-width: 320px) {
  .pb-95 {
    padding-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .mt-95 {
    margin-top: 9.5rem; } }

@media (min-width: 320px) {
  .mb-95 {
    margin-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .pt-100 {
    padding-top: 10rem; } }

@media (min-width: 320px) {
  .pb-100 {
    padding-bottom: 10rem; } }

@media (min-width: 320px) {
  .mt-100 {
    margin-top: 10rem; } }

@media (min-width: 320px) {
  .mb-100 {
    margin-bottom: 10rem; } }

h1,
.h1 {
  padding: 5rem 0;
  font-weight: 700; }
  @media (min-width: 320px) {
    h1,
    .h1 {
      font-size: 5.6rem; } }
  @media (min-width: 480px) {
    h1,
    .h1 {
      font-size: 5.09091rem; } }
  @media (min-width: 545px) {
    h1,
    .h1 {
      font-size: 4.66667rem; } }
  @media (min-width: 768px) {
    h1,
    .h1 {
      font-size: 4.5rem; } }
  @media (min-width: 320px) {
    h1,
    .h1 {
      line-height: 5.6rem; } }
  @media (min-width: 480px) {
    h1,
    .h1 {
      line-height: 5.5rem; } }

h2,
.h2 {
  font-weight: 800;
  text-transform: uppercase;
  padding: 2rem 0; }
  @media (min-width: 320px) {
    h2,
    .h2 {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 2.8rem; } }
  @media (min-width: 320px) {
    h2,
    .h2 {
      line-height: 4.4rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      line-height: 4rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      line-height: 3.66667rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      line-height: 3.5rem; } }

h3,
.h3 {
  font-weight: 700;
  padding: 1rem 0 2rem; }
  @media (min-width: 320px) {
    h3,
    .h3 {
      font-size: 3rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      font-size: 2.72727rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      font-size: 2.5rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 2.2rem; } }
  @media (min-width: 320px) {
    h3,
    .h3 {
      line-height: 4rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      line-height: 3.63636rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      line-height: 3.33333rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      line-height: 3rem; } }

.blue h3,
.blue .h3 {
  color: #007ea8; }

.green h3,
.green .h3 {
  color: #218721; }

.orange h3,
.orange .h3 {
  color: #ca4c1e; }

h4,
.h4 {
  font-weight: 700; }
  @media (min-width: 320px) {
    h4,
    .h4 {
      font-size: 2.8rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      font-size: 2rem; } }
  @media (min-width: 320px) {
    h4,
    .h4 {
      line-height: 4rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      line-height: 3.63636rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      line-height: 3.33333rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      line-height: 2.8rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    font-size: 3rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    font-size: 2.72727rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    font-size: 2.5rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    font-size: 2.3rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    line-height: 5rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    line-height: 4.54545rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    line-height: 4.16667rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    line-height: 3.33333rem; } }

@media (min-width: 992px) {
  big,
  .perex {
    line-height: 3.125rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    line-height: 3rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    font-size: 1.75rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    font-size: 1.55556rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    font-size: 1.4rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    line-height: 5rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    line-height: 4.54545rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    line-height: 4.16667rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    line-height: 3.33333rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    line-height: 3.125rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    line-height: 2.77778rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    line-height: 2.5rem; } }

a {
  cursor: pointer;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  a:focus, a:hover {
    outline: none;
    text-decoration: underline; }

.blue a {
  color: #007ea8; }

.green a {
  color: #218721; }

.orange a {
  color: #ca4c1e; }

.btn-download {
  font-size: 0; }
  .btn-download:before {
    content: "\e927"; }
    @media (min-width: 320px) {
      .btn-download:before {
        font-size: 4rem; } }
    @media (min-width: 480px) {
      .btn-download:before {
        font-size: 3.63636rem; } }
    @media (min-width: 545px) {
      .btn-download:before {
        font-size: 3.33333rem; } }
    @media (min-width: 768px) {
      .btn-download:before {
        font-size: 2.66667rem; } }
    @media (min-width: 992px) {
      .btn-download:before {
        font-size: 2.5rem; } }
  .btn-download:focus, .btn-download:hover {
    text-decoration: none; }

.btn {
  cursor: pointer;
  font-size: 15px;
  display: inline-block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
  padding: 2rem 4rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2.25px;
  border-radius: 50px;
  transition: 0.3s ease all; }
  .btn:focus, .btn:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
  .btn.btn-primary {
    background-color: #fff;
    color: #4c4c4c; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background-color: #4c4c4c;
      color: #fff; }
  .btn.btn-default, .btn.btn-load-more {
    background-color: #007e41;
    color: #fff; }
    .btn.btn-default:hover, .btn.btn-default:focus, .btn.btn-load-more:hover, .btn.btn-load-more:focus {
      opacity: 0.9; }

.pux-infinite-repeater-options-inner-load-more {
  padding-top: 2rem !important; }

.grey-bgr {
  background: #f2fbf8; }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top left !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 62.7rem;
  padding-top: 70px; }
  @media (min-width: 1200px) {
    main {
      padding-top: 10rem; } }
  main ol {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: my-ol; }
    main ol li {
      position: relative;
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 30px; }
      main ol li:before {
        content: counter(my-ol) ".";
        counter-increment: my-ol;
        position: absolute;
        left: 0;
        top: 3px;
        font-family: "proxima-nova", sans-serif !important;
        font-weight: bold; }
  main ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    main ul li {
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 30px;
      position: relative; }
      main ul li:before {
        content: "";
        width: 6px;
        height: 6px;
        position: absolute;
        background-color: #007e41;
        top: 7px;
        left: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
  main table {
    width: 100%; }
    main table tr td {
      padding: 1rem 1.5rem; }
  main .respo-table table {
    width: 100%; }
    main .respo-table table thead {
      display: none; }
      @media (min-width: 768px) {
        main .respo-table table thead {
          display: table-header-group; } }
      main .respo-table table thead tr th {
        font-weight: bold;
        padding: 1rem 1.5rem; }
    main .respo-table table tbody tr {
      display: block;
      border-bottom: none;
      margin: 0 0 2rem 0; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none; } }
      main .respo-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td {
            display: table-cell;
            border: none;
            width: auto; } }
        main .respo-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "proxima-nova", sans-serif !important;
          margin: 0 1.5rem 0 0; }
          @media (min-width: 768px) {
            main .respo-table table tbody tr td:before {
              display: none; } }

.mobile-title h1 {
  padding-top: 0; }

@media (min-width: 768px) {
  .mobile-title {
    display: none; } }

.service-subtitle {
  font-size: 5.6rem;
  line-height: 7rem;
  font-weight: 800;
  padding: 0 0 1rem 0;
  text-transform: none; }
  @media (min-width: 480px) {
    .service-subtitle {
      font-size: 5.09091rem; } }
  @media (min-width: 545px) {
    .service-subtitle {
      font-size: 4.66667rem; } }
  @media (min-width: 768px) {
    .service-subtitle {
      font-size: 3.73333rem; } }
  @media (min-width: 992px) {
    .service-subtitle {
      font-size: 3.5rem; } }
  @media (min-width: 1200px) {
    .service-subtitle {
      font-size: 3.11111rem; } }
  @media (min-width: 1400px) {
    .service-subtitle {
      font-size: 2.8rem; } }
  @media (min-width: 480px) {
    .service-subtitle {
      line-height: 6.36364rem; } }
  @media (min-width: 545px) {
    .service-subtitle {
      line-height: 5.83333rem; } }
  @media (min-width: 768px) {
    .service-subtitle {
      line-height: 4.66667rem; } }
  @media (min-width: 992px) {
    .service-subtitle {
      line-height: 4.375rem; } }
  @media (min-width: 1200px) {
    .service-subtitle {
      line-height: 3.88889rem; } }
  @media (min-width: 1400px) {
    .service-subtitle {
      line-height: 3.5rem; } }
  @media (min-width: 768px) {
    .service-subtitle {
      text-transform: uppercase;
      padding: 2rem 0; } }

.label-form,
label {
  display: block;
  margin: 1rem 0 0.5rem 0;
  font-size: 2.8rem; }
  @media (min-width: 480px) {
    .label-form,
    label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .label-form,
    label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .label-form,
    label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .label-form,
    label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .label-form,
    label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .label-form,
    label {
      font-size: 1.4rem; } }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.form-control,
textarea,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 0 1.5rem;
  font-size: 2.8rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  border-radius: 0.5rem; }
  @media (min-width: 320px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 8rem; } }
  @media (min-width: 480px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 7.27273rem; } }
  @media (min-width: 545px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 6.66667rem; } }
  @media (min-width: 768px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5.33333rem; } }
  @media (min-width: 992px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5rem; } }
  @media (min-width: 480px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.4rem; } }
  .form-control:focus,
  textarea:focus,
  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: 0; }

textarea.form-control,
textarea {
  padding: 1.5rem; }
  @media (min-width: 320px) {
    textarea.form-control,
    textarea {
      height: 20rem; } }
  @media (min-width: 480px) {
    textarea.form-control,
    textarea {
      height: 18.18182rem; } }
  @media (min-width: 545px) {
    textarea.form-control,
    textarea {
      height: 16.66667rem; } }
  @media (min-width: 768px) {
    textarea.form-control,
    textarea {
      height: 15rem; } }

input[type="submit"] {
  cursor: pointer; }

.required label:before {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 480px) {
    .required label:before {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    .required label:before {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    .required label:before {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    .required label:before {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .required label:before {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    .required label:before {
      font-size: 1rem; } }

label.required:after {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 480px) {
    label.required:after {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    label.required:after {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    label.required:after {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    label.required:after {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    label.required:after {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    label.required:after {
      font-size: 1rem; } }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 2.8rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  max-width: 24rem;
  margin: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important; }
  @media (min-width: 480px) {
    .file label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .file label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .file label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .file label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .file label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .file label {
      font-size: 1.4rem; } }

.file .file-name {
  padding: 0.5rem; }

.form-validation-error select,
.form-validation-error .selectric,
.form-validation-error input[type="text"],
.form-validation-error input[type="password"],
.form-validation-error input[type="email"],
.form-validation-error input[type="number"],
.form-validation-error textarea,
.Error:not(.form-validation-valid) select,
.Error:not(.form-validation-valid) .selectric,
.Error:not(.form-validation-valid) input[type="text"],
.Error:not(.form-validation-valid) input[type="password"],
.Error:not(.form-validation-valid) input[type="email"],
.Error:not(.form-validation-valid) input[type="number"],
.Error:not(.form-validation-valid) textarea {
  border: 1px solid #d40511 !important;
  -webkit-box-shadow: inset 0 0 0 1px #d40511;
  -moz-box-shadow: inset 0 0 0 1px #d40511;
  box-shadow: inset 0 0 0 1px #d40511;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal; }

.form-validation-error select,
.form-validation-error .selectric,
.form-validation-error input[type="text"],
.form-validation-error input[type="password"],
.form-validation-error input[type="email"],
.form-validation-error input[type="number"],
.form-validation-error textarea {
  padding-right: 6rem; }

@-webkit-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-moz-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-ms-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

.error-span {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background: #cdcdcd;
  position: absolute;
  top: 1rem;
  right: 1rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%; }
  @media (min-width: 320px) {
    .error-span {
      height: 6rem; } }
  @media (min-width: 480px) {
    .error-span {
      height: 5.45455rem; } }
  @media (min-width: 545px) {
    .error-span {
      height: 5rem; } }
  @media (min-width: 768px) {
    .error-span {
      height: 4rem; } }
  @media (min-width: 320px) {
    .error-span {
      width: 6rem; } }
  @media (min-width: 480px) {
    .error-span {
      width: 5.45455rem; } }
  @media (min-width: 545px) {
    .error-span {
      width: 5rem; } }
  @media (min-width: 768px) {
    .error-span {
      width: 4rem; } }
  @media (min-width: 480px) {
    .error-span {
      top: 0.90909rem; } }
  @media (min-width: 545px) {
    .error-span {
      top: 0.83333rem; } }
  @media (min-width: 768px) {
    .error-span {
      top: 0.66667rem; } }
  @media (min-width: 992px) {
    .error-span {
      top: 0.625rem; } }
  @media (min-width: 1200px) {
    .error-span {
      top: 0.55556rem; } }
  @media (min-width: 1400px) {
    .error-span {
      top: 0.5rem; } }
  @media (min-width: 480px) {
    .error-span {
      right: 0.90909rem; } }
  @media (min-width: 545px) {
    .error-span {
      right: 0.83333rem; } }
  @media (min-width: 768px) {
    .error-span {
      right: 0.66667rem; } }
  @media (min-width: 992px) {
    .error-span {
      right: 0.625rem; } }
  @media (min-width: 1200px) {
    .error-span {
      right: 0.55556rem; } }
  @media (min-width: 1400px) {
    .error-span {
      right: 0.5rem; } }

div.form-validation-error .error-span:before {
  content: "\2715";
  color: red; }

div.form-validation-valid .FormErrorLabel,
div.form-validation-valid .EditingFormErrorLabel {
  display: none; }

div.form-validation-valid .error-span:before {
  content: "\2713";
  color: green; }

div.form-validation-error,
div.form-validation-valid {
  position: relative; }

.ErrorLabel,
.form-control-error,
div.form-validation-error,
.EditingFormErrorLabel {
  font-size: 2.8rem;
  color: #d40511;
  display: block;
  margin: 0.5rem 0 0 0; }
  @media (min-width: 480px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.4rem; } }

.radio input[type="radio"] {
  display: none; }
  .radio input[type="radio"] + label {
    position: relative;
    padding: 0 0 1rem 25px;
    display: inline-block;
    margin: 0; }
    .radio input[type="radio"] + label:before {
      content: "";
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .radio input[type="radio"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .radio input[type="radio"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.checkbox input[type="checkbox"] {
  position: absolute;
  top: 2px;
  left: 2px; }
  .checkbox input[type="checkbox"] + label {
    position: relative;
    padding: 0 0 1rem 25px;
    margin: 0;
    display: inline-block; }
    .checkbox input[type="checkbox"] + label:before {
      content: "";
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type="checkbox"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .checkbox input[type="checkbox"]:focus + label:before {
    border: 2px solid; }

.row-form {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1rem; }

.row-form [class^="col"] {
  padding-left: 1rem;
  padding-right: 1rem; }

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0; }

.selectric-responsive {
  width: 100%; }

.selectric {
  position: relative;
  margin: 0 1rem 0 0;
  width: 40rem;
  overflow: hidden;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  border-radius: 0.5rem; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    padding: 0 2rem 0 2rem; }
    @media (min-width: 320px) {
      .selectric .label {
        line-height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        line-height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        line-height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        line-height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        line-height: 5rem; } }
    @media (min-width: 320px) {
      .selectric .label {
        height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        height: 5rem; } }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 5rem;
    height: 100%;
    text-align: center;
    transition: all ease 200ms;
    font-size: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    .selectric .button:after {
      font-size: 1.6rem;
      content: ""; }
      @media (min-width: 480px) {
        .selectric .button:after {
          font-size: 1.45455rem; } }
      @media (min-width: 545px) {
        .selectric .button:after {
          font-size: 1.33333rem; } }
      @media (min-width: 768px) {
        .selectric .button:after {
          font-size: 1.06667rem; } }
      @media (min-width: 992px) {
        .selectric .button:after {
          font-size: 1rem; } }
      @media (min-width: 1200px) {
        .selectric .button:after {
          font-size: 0.88889rem; } }
      @media (min-width: 1400px) {
        .selectric .button:after {
          font-size: 0.8rem; } }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric .button {
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block; }

.selectric-disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  overflow: hidden;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  border-top: 1px;
  z-index: -1; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 2rem;
    min-height: 2rem; }
  .selectric-items li {
    display: block;
    padding: 1rem 2rem;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li:before {
      display: none; }
  .selectric-items .disabled {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    cursor: default !important;
    background: none !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 1rem;
    cursor: default;
    user-select: none;
    background: none; }
  .selectric-items .selectric-group.disabled li {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .selectric-items .selectric-group li {
    padding-left: 2.5rem; }

/*!
 * Datepicker for Bootstrap v1.8.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  padding: 0.4rem;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  border-radius: 0.4rem;
  direction: ltr;
  font-size: 2.4rem; }
  @media (min-width: 480px) {
    .datepicker {
      font-size: 2.18182rem; } }
  @media (min-width: 545px) {
    .datepicker {
      font-size: 2rem; } }
  @media (min-width: 768px) {
    .datepicker {
      font-size: 1.6rem; } }
  @media (min-width: 992px) {
    .datepicker {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    .datepicker {
      font-size: 1.33333rem; } }
  @media (min-width: 1400px) {
    .datepicker {
      font-size: 1.2rem; } }
  .datepicker-inline {
    width: 22rem; }
  .datepicker-rtl {
    direction: rtl; }
    .datepicker-rtl.dropdown-menu {
      left: auto; }
    .datepicker-rtl table tr td span {
      float: right; }
  .datepicker-dropdown {
    top: 0;
    left: 0; }
    .datepicker-dropdown:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #999;
      border-top: 0;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      position: absolute; }
    .datepicker-dropdown:after {
      content: '';
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #999;
      border-top: 0;
      position: absolute; }

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px; }

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px; }

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px; }

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px; }

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px; }

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px; }

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999; }

.datepicker-dropdown.datepicker-orient-top:after {
  top: -6px;
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff; }

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  border-radius: 0.4rem;
  border: none; }

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent; }

.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
  background: #eee;
  cursor: pointer; }

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #999; }

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #999;
  cursor: default; }

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0; }

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #4c4c4c;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #4c4c4c 0%, #333333 100%);
  background-image: -o-linear-gradient(top, #4c4c4c 0%, #333333 100%);
  background-image: linear-gradient(to bottom, #4c4c4c 0%, #333333 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF4C4C4C', endColorstr='#FF333333', GradientType=0); }

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: #fdf59a; }

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  background-color: #fbf069 \9; }

.datepicker table tr td.today:hover:hover {
  color: #fff; }

.datepicker table tr td.today.active:hover {
  color: #fff; }

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  background: #eee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
  background-color: #f3d17a;
  background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
  background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
  background-color: #f3e97a; }

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
  background-color: #efe24b \9; }

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #9e9e9e;
  background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
  background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: linear-gradient(to bottom, #b3b3b3, #808080);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
  border-color: #808080 #808080 #595959;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
  background-color: #808080; }

.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
  background-color: #666666 \9; }

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #007e41;
  background-image: -webkit-linear-gradient(top, #007e41 0%, #004b27 100%);
  background-image: -o-linear-gradient(top, #007e41 0%, #004b27 100%);
  background-image: linear-gradient(to bottom, #007e41 0%, #004b27 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF007E41', endColorstr='#FF004B27', GradientType=0);
  color: #fff; }

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background-color: #007e41;
  background-image: -webkit-linear-gradient(top, #007e41 0%, #004b27 100%);
  background-image: -o-linear-gradient(top, #007e41 0%, #004b27 100%);
  background-image: linear-gradient(to bottom, #007e41 0%, #004b27 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF007E41', endColorstr='#FF004B27', GradientType=0);
  color: #fff; }

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  background-color: #007e41;
  background-image: -webkit-linear-gradient(top, #007e41 0%, #004b27 100%);
  background-image: -o-linear-gradient(top, #007e41 0%, #004b27 100%);
  background-image: linear-gradient(to bottom, #007e41 0%, #004b27 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF007E41', endColorstr='#FF004B27', GradientType=0);
  color: #fff; }

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.datepicker table tr td span:hover {
  background-color: #eee; }

.datepicker table tr td span.focused {
  background-color: #007e41;
  background-image: -webkit-linear-gradient(top, #007e41 0%, #004b27 100%);
  background-image: -o-linear-gradient(top, #007e41 0%, #004b27 100%);
  background-image: linear-gradient(to bottom, #007e41 0%, #004b27 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF007E41', endColorstr='#FF004B27', GradientType=0);
  color: #fff; }

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #999;
  cursor: default; }

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: #007e41;
  background-image: -webkit-linear-gradient(top, #007e41 0%, #004b27 100%);
  background-image: -o-linear-gradient(top, #007e41 0%, #004b27 100%);
  background-image: linear-gradient(to bottom, #007e41 0%, #004b27 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF007E41', endColorstr='#FF004B27', GradientType=0);
  color: #fff; }

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #4c4c4c;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #4c4c4c 0%, #333333 100%);
  background-image: -o-linear-gradient(top, #4c4c4c 0%, #333333 100%);
  background-image: linear-gradient(to bottom, #4c4c4c 0%, #333333 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF4C4C4C', endColorstr='#FF333333', GradientType=0); }

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  background-color: #4c4c4c;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #4c4c4c 0%, #333333 100%);
  background-image: -o-linear-gradient(top, #4c4c4c 0%, #333333 100%);
  background-image: linear-gradient(to bottom, #4c4c4c 0%, #333333 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF4C4C4C', endColorstr='#FF333333', GradientType=0); }

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #999; }

.datepicker .datepicker-switch {
  width: 145px; }

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer; }

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: #eee; }

.datepicker .prev.disabled,
.datepicker .next.disabled {
  visibility: hidden; }

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle; }

.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer; }

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px; }

.input-daterange input {
  text-align: center; }

.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px; }

.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0; }

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px; }

/*# sourceMappingURL=bootstrap-datepicker.css.map */
@media print {
  header,
  footer {
    display: none; } }

.slick-track {
  display: flex; }

.slick-track .slick-slide {
  display: flex;
  height: auto; }

.slider-init .main-slider {
  width: 100%;
  background-repeat: no-repeat !important;
  display: flex;
  position: relative;
  -webkit-background-size: cover !important;
  background-size: cover !important; }
  .slider-init .main-slider:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(46, 46, 46, 0.3); }
    @media (min-width: 768px) {
      .slider-init .main-slider:after {
        background-color: transparent;
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0) 100%); } }
  .slider-init .main-slider .pux-container {
    width: 100%; }
  .slider-init .main-slider-inner {
    width: 100%;
    padding: 0 0 10rem 0;
    min-height: 60rem;
    max-height: calc(100vh - $slider-header-height);
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: flex-start;
    -moz-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    z-index: 2; }
    .slider-init .main-slider-inner-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 75rem; }
      .slider-init .main-slider-inner-content-text {
        color: #fff; }
        .slider-init .main-slider-inner-content-text h1,
        .slider-init .main-slider-inner-content-text h2 {
          color: #fff;
          padding: 5rem 0 3rem; }
      .slider-init .main-slider-inner-content-btn {
        padding: 5rem 0 2rem 0; }
  .slider-init .main-slider.top-center {
    background-position: top center !important; }
  .slider-init .main-slider.top-left {
    background-position: top left !important; }
  .slider-init .main-slider.top-right {
    background-position: top right !important; }
  .slider-init .main-slider.center-center {
    background-position: center center !important; }
  .slider-init .main-slider.bottom-center {
    background-position: bottom center !important; }
  .slider-init .main-slider.bottom-left {
    background-position: bottom left !important; }
  .slider-init .main-slider.bottom-right {
    background-position: bottom right !important; }

.slider-init .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -2rem;
  width: 4rem;
  height: 4rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: none;
  font-size: 0;
  background: transparent;
  outline: 0;
  z-index: 22;
  cursor: pointer; }
  .slider-init .slick-arrow:before {
    font-size: 20px; }
  .slider-init .slick-arrow.slick-prev {
    left: 2rem; }
    .slider-init .slick-arrow.slick-prev:before {
      content: "";
      display: block;
      opacity: 0.5;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity=50);
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg); }
  .slider-init .slick-arrow.slick-next {
    right: 2rem; }
    .slider-init .slick-arrow.slick-next:before {
      content: "";
      display: block;
      -ms-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0.5;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity=50); }

.slider-init .slick-dots {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: flex-end;
  -moz-box-align: flex-end;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  left: 0;
  width: 100%;
  max-width: 172rem;
  margin: auto;
  bottom: 0;
  padding: 0 0 6rem;
  top: auto !important;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .slider-init .slick-dots {
      padding-left: 6rem;
      padding-right: 6rem; } }
  .slider-init .slick-dots li {
    margin: 0;
    padding: 0 2rem 0 0; }
    .slider-init .slick-dots li:before {
      display: none; }
    .slider-init .slick-dots li:last-child {
      padding: 0; }
    .slider-init .slick-dots li button {
      font-size: 0;
      outline: 0;
      width: 6rem;
      height: 6rem;
      border: none;
      background: #fff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      padding: 0;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      opacity: 0.3;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
      filter: alpha(opacity=30);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      cursor: pointer; }
      @media (min-width: 480px) {
        .slider-init .slick-dots li button {
          width: 5.45455rem; } }
      @media (min-width: 545px) {
        .slider-init .slick-dots li button {
          width: 5rem; } }
      @media (min-width: 768px) {
        .slider-init .slick-dots li button {
          width: 4rem; } }
      @media (min-width: 992px) {
        .slider-init .slick-dots li button {
          width: 3.75rem; } }
      @media (min-width: 1200px) {
        .slider-init .slick-dots li button {
          width: 3.33333rem; } }
      @media (min-width: 1400px) {
        .slider-init .slick-dots li button {
          width: 3rem; } }
      @media (min-width: 480px) {
        .slider-init .slick-dots li button {
          height: 5.45455rem; } }
      @media (min-width: 545px) {
        .slider-init .slick-dots li button {
          height: 5rem; } }
      @media (min-width: 768px) {
        .slider-init .slick-dots li button {
          height: 4rem; } }
      @media (min-width: 992px) {
        .slider-init .slick-dots li button {
          height: 3.75rem; } }
      @media (min-width: 1200px) {
        .slider-init .slick-dots li button {
          height: 3.33333rem; } }
      @media (min-width: 1400px) {
        .slider-init .slick-dots li button {
          height: 3rem; } }
      .slider-init .slick-dots li button:before {
        content: "";
        width: 2rem;
        height: 2rem;
        background: #4c4c4c;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
        @media (min-width: 480px) {
          .slider-init .slick-dots li button:before {
            width: 1.81818rem; } }
        @media (min-width: 545px) {
          .slider-init .slick-dots li button:before {
            width: 1.66667rem; } }
        @media (min-width: 768px) {
          .slider-init .slick-dots li button:before {
            width: 1.33333rem; } }
        @media (min-width: 992px) {
          .slider-init .slick-dots li button:before {
            width: 1.25rem; } }
        @media (min-width: 1200px) {
          .slider-init .slick-dots li button:before {
            width: 1.11111rem; } }
        @media (min-width: 1400px) {
          .slider-init .slick-dots li button:before {
            width: 1rem; } }
        @media (min-width: 480px) {
          .slider-init .slick-dots li button:before {
            height: 1.81818rem; } }
        @media (min-width: 545px) {
          .slider-init .slick-dots li button:before {
            height: 1.66667rem; } }
        @media (min-width: 768px) {
          .slider-init .slick-dots li button:before {
            height: 1.33333rem; } }
        @media (min-width: 992px) {
          .slider-init .slick-dots li button:before {
            height: 1.25rem; } }
        @media (min-width: 1200px) {
          .slider-init .slick-dots li button:before {
            height: 1.11111rem; } }
        @media (min-width: 1400px) {
          .slider-init .slick-dots li button:before {
            height: 1rem; } }
      .slider-init .slick-dots li button:hover {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100);
        background: #fff; }
    .slider-init .slick-dots li.slick-active button {
      background: #fff;
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100); }

.slider-wrapper {
  position: relative;
  display: none; }
  @media (min-width: 768px) {
    .slider-wrapper {
      display: block; } }

.slick-track {
  position: relative; }

.dots-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0; }
  .dots-wrapper .slick-dots {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: flex-end;
    -moz-box-align: flex-end;
    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    left: 0;
    width: 100%;
    max-width: 172rem;
    margin: auto;
    bottom: 0;
    padding: 0 0 6rem;
    top: auto !important;
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 768px) {
      .dots-wrapper .slick-dots {
        padding-left: 6rem;
        padding-right: 6rem; } }
    .dots-wrapper .slick-dots li {
      margin: 0;
      padding: 0 2rem 0 0; }
      .dots-wrapper .slick-dots li:before {
        display: none; }
      .dots-wrapper .slick-dots li:last-child {
        padding: 0; }
      .dots-wrapper .slick-dots li button {
        font-size: 0;
        outline: 0;
        width: 6rem;
        height: 6rem;
        border: none;
        background: #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        padding: 0;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        opacity: 0.3;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
        filter: alpha(opacity=30);
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms;
        cursor: pointer; }
        @media (min-width: 480px) {
          .dots-wrapper .slick-dots li button {
            width: 5.45455rem; } }
        @media (min-width: 545px) {
          .dots-wrapper .slick-dots li button {
            width: 5rem; } }
        @media (min-width: 768px) {
          .dots-wrapper .slick-dots li button {
            width: 4rem; } }
        @media (min-width: 992px) {
          .dots-wrapper .slick-dots li button {
            width: 3.75rem; } }
        @media (min-width: 1200px) {
          .dots-wrapper .slick-dots li button {
            width: 3.33333rem; } }
        @media (min-width: 1400px) {
          .dots-wrapper .slick-dots li button {
            width: 3rem; } }
        @media (min-width: 480px) {
          .dots-wrapper .slick-dots li button {
            height: 5.45455rem; } }
        @media (min-width: 545px) {
          .dots-wrapper .slick-dots li button {
            height: 5rem; } }
        @media (min-width: 768px) {
          .dots-wrapper .slick-dots li button {
            height: 4rem; } }
        @media (min-width: 992px) {
          .dots-wrapper .slick-dots li button {
            height: 3.75rem; } }
        @media (min-width: 1200px) {
          .dots-wrapper .slick-dots li button {
            height: 3.33333rem; } }
        @media (min-width: 1400px) {
          .dots-wrapper .slick-dots li button {
            height: 3rem; } }
        .dots-wrapper .slick-dots li button:before {
          content: "";
          width: 2rem;
          height: 2rem;
          background: #4c4c4c;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          border-radius: 50%; }
          @media (min-width: 480px) {
            .dots-wrapper .slick-dots li button:before {
              width: 1.81818rem; } }
          @media (min-width: 545px) {
            .dots-wrapper .slick-dots li button:before {
              width: 1.66667rem; } }
          @media (min-width: 768px) {
            .dots-wrapper .slick-dots li button:before {
              width: 1.33333rem; } }
          @media (min-width: 992px) {
            .dots-wrapper .slick-dots li button:before {
              width: 1.25rem; } }
          @media (min-width: 1200px) {
            .dots-wrapper .slick-dots li button:before {
              width: 1.11111rem; } }
          @media (min-width: 1400px) {
            .dots-wrapper .slick-dots li button:before {
              width: 1rem; } }
          @media (min-width: 480px) {
            .dots-wrapper .slick-dots li button:before {
              height: 1.81818rem; } }
          @media (min-width: 545px) {
            .dots-wrapper .slick-dots li button:before {
              height: 1.66667rem; } }
          @media (min-width: 768px) {
            .dots-wrapper .slick-dots li button:before {
              height: 1.33333rem; } }
          @media (min-width: 992px) {
            .dots-wrapper .slick-dots li button:before {
              height: 1.25rem; } }
          @media (min-width: 1200px) {
            .dots-wrapper .slick-dots li button:before {
              height: 1.11111rem; } }
          @media (min-width: 1400px) {
            .dots-wrapper .slick-dots li button:before {
              height: 1rem; } }
        .dots-wrapper .slick-dots li button:hover {
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100);
          background: #fff; }
      .dots-wrapper .slick-dots li.slick-active button {
        background: #fff;
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100); }

.slider-placeholder {
  width: 15rem; }

.slider-init {
  height: 100%; }
  .slider-init .slick-list {
    height: 100%; }
    .slider-init .slick-list .slick-track {
      height: 100%; }

.main-slider-inner-content-btn .btn-primary {
  white-space: normal;
  padding: 2rem 3rem; }

.pux-infinite-repeater-options-inner-load-more {
  padding: 5rem 0 0 0; }

.map-box {
  position: relative;
  width: 100%;
  text-align: center; }
  .map-box-name {
    padding: 1.5rem 3rem;
    -webkit-border-radius: 6rem;
    -moz-border-radius: 6rem;
    -ms-border-radius: 6rem;
    border-radius: 6rem;
    display: inline-block;
    position: relative;
    z-index: 888;
    -webkit-transform: translate(0, 50%);
    -moz-transform: translate(0, 50%);
    -ms-transform: translate(0, 50%);
    -o-transform: translate(0, 50%);
    transform: translate(0, 50%); }
  .map-box #map-canvas {
    width: 100%;
    height: 50rem; }

.map-points {
  display: none; }

.marker-detail {
  display: none; }

.marker-detail-close {
  opacity: 1 !important; }
  .marker-detail-close img {
    display: none !important; }
  .marker-detail-close:before {
    content: "\03a7";
    font-family: "proxima-nova", sans-serif !important; }

.top-image {
  display: none;
  position: relative; }
  @media (min-width: 768px) {
    .top-image {
      display: block;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important; }
      .top-image h1 {
        color: #fff;
        position: relative;
        z-index: 2;
        padding: 0; } }
  .top-image:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(46, 46, 46, 0.3); }

.service-item {
  box-shadow: 0 20px 70px rgba(41, 41, 41, 0.07);
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 25px;
  overflow: hidden; }
  .service-item-image {
    width: 100%;
    height: 260px;
    position: relative; }
    @media (min-width: 768px) {
      .service-item-image {
        height: 26rem; } }
    .service-item-image-bgr {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important; }
  .service-item-content {
    text-align: center;
    padding: 2rem 6rem 4rem; }
    @media (min-width: 992px) {
      .service-item-content {
        padding: 2rem 2rem 4rem; } }
    @media (min-width: 1400px) {
      .service-item-content {
        padding: 2rem 6rem 4rem; } }
    .service-item-content .h3, .service-item-content h3 {
      padding-bottom: 1.5rem;
      display: block; }
      @media (min-width: 320px) {
        .service-item-content .h3, .service-item-content h3 {
          font-size: 4rem; } }
      @media (min-width: 480px) {
        .service-item-content .h3, .service-item-content h3 {
          font-size: 3.63636rem; } }
      @media (min-width: 545px) {
        .service-item-content .h3, .service-item-content h3 {
          font-size: 3.33333rem; } }
      @media (min-width: 768px) {
        .service-item-content .h3, .service-item-content h3 {
          font-size: 2.66667rem; } }
      @media (min-width: 992px) {
        .service-item-content .h3, .service-item-content h3 {
          font-size: 2.6rem; } }
      .service-item-content .h3:hover, .service-item-content .h3:focus, .service-item-content h3:hover, .service-item-content h3:focus {
        text-decoration: none; }
    .service-item-content-btn {
      padding: 3rem 0 3.5rem; }
    .service-item-content-labels li {
      display: inline-block;
      padding: 0;
      margin: 0; }
    .service-item-content-labels .service-item-label {
      display: inline-block;
      padding: 2px 15px;
      font-size: 3rem;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      border-radius: 4px;
      margin: 5px;
      border-radius: 13px;
      transition: 0.3s; }
      @media (min-width: 480px) {
        .service-item-content-labels .service-item-label {
          font-size: 2.72727rem; } }
      @media (min-width: 545px) {
        .service-item-content-labels .service-item-label {
          font-size: 2.5rem; } }
      @media (min-width: 768px) {
        .service-item-content-labels .service-item-label {
          font-size: 2rem; } }
      @media (min-width: 992px) {
        .service-item-content-labels .service-item-label {
          font-size: 1.875rem; } }
      @media (min-width: 1200px) {
        .service-item-content-labels .service-item-label {
          font-size: 1.66667rem; } }
      @media (min-width: 1400px) {
        .service-item-content-labels .service-item-label {
          font-size: 1.5rem; } }
      .service-item-content-labels .service-item-label:hover, .service-item-content-labels .service-item-label:focus {
        text-decoration: none; }
  .service-item.blue .h3 {
    color: #007ea8; }
    .service-item.blue .h3:hover, .service-item.blue .h3:focus {
      color: #4c4c4c; }
  .service-item.blue .btn-primary {
    background: #007ea8;
    color: #fff; }
    .service-item.blue .btn-primary:focus, .service-item.blue .btn-primary:hover {
      background: #4c4c4c; }
  .service-item.blue .service-item-label {
    color: #007ea8;
    border: 1px solid #007ea8; }
    .service-item.blue .service-item-label:hover, .service-item.blue .service-item-label:focus {
      background-color: #007ea8;
      color: #fff; }
  .service-item.green .h3 {
    color: #218721; }
    .service-item.green .h3:hover, .service-item.green .h3:focus {
      color: #4c4c4c; }
  .service-item.green .btn-primary {
    background: #218721;
    color: #fff; }
    .service-item.green .btn-primary:focus, .service-item.green .btn-primary:hover {
      background: #4c4c4c; }
  .service-item.green .service-item-label {
    color: #218721;
    border: 1px solid #218721; }
    .service-item.green .service-item-label:hover, .service-item.green .service-item-label:focus {
      background-color: #218721;
      color: #fff; }
  .service-item.orange .h3 {
    color: #ca4c1e; }
    .service-item.orange .h3:hover, .service-item.orange .h3:focus {
      color: #4c4c4c; }
  .service-item.orange .btn-primary {
    background: #ca4c1e;
    color: #fff; }
    .service-item.orange .btn-primary:focus, .service-item.orange .btn-primary:hover {
      background: #4c4c4c; }
  .service-item.orange .service-item-label {
    color: #ca4c1e;
    border: 1px solid #ca4c1e; }
    .service-item.orange .service-item-label:hover, .service-item.orange .service-item-label:focus {
      background-color: #ca4c1e;
      color: #fff; }

.row.services {
  margin: 0 -10px !important; }
  .row.services [class^="col-"] {
    padding: 10px; }

.service-title {
  padding-bottom: 6rem; }
  .service-title h1, .service-title h2 {
    padding: 0; }

.service-subtitle {
  padding: 7rem 0 4rem 0; }
  @media (min-width: 320px) and (max-width: 544px) {
    .service-subtitle {
      font-size: 6.6rem; } }
  @media (min-width: 320px) and (max-width: 544px) and (min-width: 480px) {
    .service-subtitle {
      font-size: 6rem; } }
  @media (min-width: 320px) and (max-width: 544px) and (min-width: 545px) {
    .service-subtitle {
      font-size: 5.5rem; } }
  @media (min-width: 320px) and (max-width: 544px) and (min-width: 768px) {
    .service-subtitle {
      font-size: 4.4rem; } }
  @media (min-width: 320px) and (max-width: 544px) and (min-width: 992px) {
    .service-subtitle {
      font-size: 4.125rem; } }
  @media (min-width: 320px) and (max-width: 544px) and (min-width: 1200px) {
    .service-subtitle {
      font-size: 3.66667rem; } }
  @media (min-width: 320px) and (max-width: 544px) and (min-width: 1400px) {
    .service-subtitle {
      font-size: 3.3rem; } }
  @media (min-width: 768px) {
    .service-subtitle {
      padding: 8rem 0;
      margin: 0; } }

.service-container {
  padding: 7rem 0 0; }

.list-switcher {
  display: none; }
  @media (min-width: 768px) {
    .list-switcher {
      display: flex;
      align-items: center;
      justify-content: space-between; } }
  .list-switcher-inner {
    display: flex; }
  .list-switcher .switcher-list-basic,
  .list-switcher .switcher-list-long {
    background-color: #cdcdcd;
    padding: 1rem;
    border-radius: 10px;
    text-align: center;
    cursor: pointer; }
    .list-switcher .switcher-list-basic:before,
    .list-switcher .switcher-list-long:before {
      color: #fff;
      font-size: 30px;
      display: inline;
      vertical-align: middle; }
  .list-switcher .switcher-list-basic:before {
    content: '\e92a'; }
  .list-switcher .switcher-list-long {
    margin-left: 1rem; }
    .list-switcher .switcher-list-long:before {
      content: '\e929'; }

#map-search-result {
  font-size: 18px; }
  #map-search-result > div {
    display: none; }

.blue .switcher-list-basic.active,
.blue .switcher-list-long.active {
  background: #007ea8; }

.green .switcher-list-basic.active,
.green .switcher-list-long.active {
  background: #218721; }

.orange .switcher-list-basic.active,
.orange .switcher-list-long.active {
  background: #ca4c1e; }

.cta-item {
  background: #fff;
  padding: 4rem;
  margin: 10px 0 10px;
  box-shadow: 0 20px 70px rgba(41, 41, 41, 0.07); }
  .cta-item h1, .cta-item h2, .cta-item h3, .cta-item h4 {
    padding-top: 0; }
  .cta-item-btn {
    padding-top: 3rem;
    text-align: center; }

.gallery-item.row {
  margin: 3rem -10px; }
  .gallery-item.row [class^="col-"] {
    padding: 10px; }
  .gallery-item.row a {
    display: block;
    position: relative;
    height: 240px; }
    @media (min-width: 768px) {
      .gallery-item.row a {
        height: 24rem; } }
    .gallery-item.row a .gallery-item-image-bgr {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important; }

@media (min-width: 545px) {
  .homes-filter {
    margin-bottom: 4.5rem; } }

@media (min-width: 992px) {
  .homes-filter {
    display: flex; } }

@media (min-width: 992px) {
  .homes-filter-content {
    padding: 4rem 4rem 3rem;
    background-color: #fff;
    flex: 0 0 52rem;
    max-width: 52rem;
    border-radius: 2.5rem 0 0 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 58rem; } }

.homes-filter-content h3 {
  color: #4c4c4c;
  margin-top: 0;
  padding-top: 0;
  display: none; }
  @media (min-width: 992px) {
    .homes-filter-content h3 {
      display: inline-block; } }

@media (min-width: 320px) and (max-width: 991px) {
  .homes-filter-content-top {
    display: flex;
    flex-direction: column; } }

.homes-filter-content-bottom {
  margin-top: 3rem;
  display: none; }
  @media (min-width: 992px) {
    .homes-filter-content-bottom {
      border-top: 2px solid #ebebeb;
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 992px) {
    .homes-filter-content-bottom > span {
      flex: 0 1 50%;
      max-width: 100%; } }

@media (min-width: 992px) {
  .homes-filter-map {
    flex: 1 1 100%;
    max-width: calc(100% - 52rem); } }

.filter-btn {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-right: 15px; }
  @media (min-width: 320px) {
    .filter-btn {
      height: 10rem; } }
  @media (min-width: 480px) {
    .filter-btn {
      height: 9.09091rem; } }
  @media (min-width: 545px) {
    .filter-btn {
      height: 8.33333rem; } }
  @media (min-width: 768px) {
    .filter-btn {
      height: 6.66667rem; } }
  @media (min-width: 992px) {
    .filter-btn {
      height: 6.25rem; } }
  @media (min-width: 1200px) {
    .filter-btn {
      height: 6rem; } }
  @media (min-width: 992px) {
    .filter-btn {
      margin-top: 15px; } }
  @media (min-width: 992px) {
    .filter-btn {
      display: none; } }
  .filter-btn:after {
    content: "\e901";
    position: absolute;
    color: #fff;
    font-size: 10px;
    top: 50%;
    right: 22px;
    transform: translateY(-50%); }
  .filter-btn.open:after {
    transform: rotate(180deg) translateY(50%); }

.homes-filter-zip,
.homes-filter-address {
  position: relative;
  margin-top: 2rem; }
  @media (min-width: 320px) and (max-width: 991px) {
    .homes-filter-zip,
    .homes-filter-address {
      order: 1; } }
  .homes-filter-zip input,
  .homes-filter-address input {
    border: 2px solid #cdcdcd;
    border-radius: 30px;
    width: 100%;
    font-size: 16px;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    padding: 0.6rem 2.6rem;
    padding-right: 75px; }
    @media (min-width: 320px) {
      .homes-filter-zip input,
      .homes-filter-address input {
        height: 10rem; } }
    @media (min-width: 480px) {
      .homes-filter-zip input,
      .homes-filter-address input {
        height: 9.09091rem; } }
    @media (min-width: 545px) {
      .homes-filter-zip input,
      .homes-filter-address input {
        height: 8.33333rem; } }
    @media (min-width: 768px) {
      .homes-filter-zip input,
      .homes-filter-address input {
        height: 6.66667rem; } }
    @media (min-width: 992px) {
      .homes-filter-zip input,
      .homes-filter-address input {
        height: 6.25rem; } }
    @media (min-width: 1200px) {
      .homes-filter-zip input,
      .homes-filter-address input {
        height: 6rem; } }
    @media (min-width: 1200px) {
      .homes-filter-zip input,
      .homes-filter-address input {
        font-size: 2rem; } }
    @media (min-width: 1400px) {
      .homes-filter-zip input,
      .homes-filter-address input {
        font-size: 1.8rem; } }
    @media (min-width: 992px) {
      .homes-filter-zip input,
      .homes-filter-address input {
        width: 100%;
        padding-right: calc(100% - 160px); } }
    @media (min-width: 1200px) {
      .homes-filter-zip input,
      .homes-filter-address input {
        padding-right: calc(100% - 200px); } }
    .homes-filter-zip input:focus,
    .homes-filter-address input:focus {
      outline: 0; }
  .homes-filter-zip .btn,
  .homes-filter-address .btn {
    display: none !important;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 1;
    border-radius: 30px;
    padding: 2.5rem 4rem;
    color: #fff; }
    @media (min-width: 992px) {
      .homes-filter-zip .btn,
      .homes-filter-address .btn {
        padding: 2.5rem 2.6rem;
        display: inline-flex !important; } }
    @media (min-width: 1200px) {
      .homes-filter-zip .btn,
      .homes-filter-address .btn {
        padding: 2.5rem 4rem; } }
  .homes-filter-zip:before,
  .homes-filter-address:before {
    content: "\e942";
    position: absolute;
    top: 50%;
    right: 3rem;
    font-size: 27px;
    color: #007e41;
    transform: translateY(-50%); }
    @media (min-width: 992px) {
      .homes-filter-zip:before,
      .homes-filter-address:before {
        display: none; } }

.homes-filter-zip {
  display: none; }
  @media (min-width: 992px) {
    .homes-filter-zip {
      display: block; } }

@media (min-width: 992px) {
  .homes-filter-address {
    display: none; } }

.blue .homes-filter-zip input:focus {
  border-color: #007ea8; }

.blue .homes-filter-zip .btn {
  background-color: #007ea8; }
  .blue .homes-filter-zip .btn:hover, .blue .homes-filter-zip .btn:focus {
    background-color: darkenthemed("theme-color"); }

.blue .homes-filter-zip:before {
  color: #007ea8; }

.green .homes-filter-zip input:focus {
  border-color: #218721; }

.green .homes-filter-zip .btn {
  background-color: #218721; }
  .green .homes-filter-zip .btn:hover, .green .homes-filter-zip .btn:focus {
    background-color: darkenthemed("theme-color"); }

.green .homes-filter-zip:before {
  color: #218721; }

.orange .homes-filter-zip input:focus {
  border-color: #ca4c1e; }

.orange .homes-filter-zip .btn {
  background-color: #ca4c1e; }
  .orange .homes-filter-zip .btn:hover, .orange .homes-filter-zip .btn:focus {
    background-color: darkenthemed("theme-color"); }

.orange .homes-filter-zip:before {
  color: #ca4c1e; }

.blue .homes-filter-restart {
  display: none;
  font-size: 16px;
  font-weight: 700;
  margin-top: 2.4rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #007ea8; }
  @media (min-width: 992px) {
    .blue .homes-filter-restart {
      display: inline-flex;
      align-items: center; } }
  .blue .homes-filter-restart:before {
    content: "\e92c";
    margin-right: 10px;
    font-size: 14px;
    color: #007ea8; }
    @media (min-width: 992px) {
      .blue .homes-filter-restart:before {
        margin-right: 1rem; } }
  .blue .homes-filter-restart:hover, .blue .homes-filter-restart:focus {
    color: #0091c2; }
    .blue .homes-filter-restart:hover:before, .blue .homes-filter-restart:focus:before {
      color: #0091c2; }

.green .homes-filter-restart {
  display: none;
  font-size: 16px;
  font-weight: 700;
  margin-top: 2.4rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #218721; }
  @media (min-width: 992px) {
    .green .homes-filter-restart {
      display: inline-flex;
      align-items: center; } }
  .green .homes-filter-restart:before {
    content: "\e92c";
    margin-right: 10px;
    font-size: 14px;
    color: #218721; }
    @media (min-width: 992px) {
      .green .homes-filter-restart:before {
        margin-right: 1rem; } }
  .green .homes-filter-restart:hover, .green .homes-filter-restart:focus {
    color: #269b26; }
    .green .homes-filter-restart:hover:before, .green .homes-filter-restart:focus:before {
      color: #269b26; }

.orange .homes-filter-restart {
  display: none;
  font-size: 16px;
  font-weight: 700;
  margin-top: 2.4rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #ca4c1e; }
  @media (min-width: 992px) {
    .orange .homes-filter-restart {
      display: inline-flex;
      align-items: center; } }
  .orange .homes-filter-restart:before {
    content: "\e92c";
    margin-right: 10px;
    font-size: 14px;
    color: #ca4c1e; }
    @media (min-width: 992px) {
      .orange .homes-filter-restart:before {
        margin-right: 1rem; } }
  .orange .homes-filter-restart:hover, .orange .homes-filter-restart:focus {
    color: #de5523; }
    .orange .homes-filter-restart:hover:before, .orange .homes-filter-restart:focus:before {
      color: #de5523; }

.homes-map-description {
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  margin-top: 20px;
  padding-right: 2rem;
  white-space: nowrap;
  color: #4c4c4c; }
  @media (min-width: 1200px) {
    .homes-map-description {
      font-size: 2rem; } }
  @media (min-width: 1400px) {
    .homes-map-description {
      font-size: 1.8rem; } }
  @media (min-width: 992px) {
    .homes-map-description {
      margin-top: 2.5rem; } }
  .homes-map-description img {
    width: 17px;
    margin-right: 10px; }
    @media (min-width: 1200px) {
      .homes-map-description img {
        width: 2rem; } }
    @media (min-width: 1400px) {
      .homes-map-description img {
        width: 1.8rem; } }
    @media (min-width: 992px) {
      .homes-map-description img {
        margin-right: 1rem; } }
  @media (min-width: 1200px) {
    .homes-map-description:last-child img {
      width: 1.55556rem; } }
  @media (min-width: 1400px) {
    .homes-map-description:last-child img {
      width: 1.4rem; } }

.blue .information-tooltip {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  position: relative;
  vertical-align: text-top; }
  .blue .information-tooltip:before {
    content: "\e941";
    display: inline-block;
    color: #007ea8;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }

.green .information-tooltip {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  position: relative;
  vertical-align: text-top; }
  .green .information-tooltip:before {
    content: "\e941";
    display: inline-block;
    color: #218721;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }

.orange .information-tooltip {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  position: relative;
  vertical-align: text-top; }
  .orange .information-tooltip:before {
    content: "\e941";
    display: inline-block;
    color: #ca4c1e;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }

@media (min-width: 320px) and (max-width: 991px) {
  .homes-filter-labels {
    order: 2; } }

@media (min-width: 320px) and (max-width: 991px) {
  .homes-filter-labels-inner {
    order: 2; } }

@media (min-width: 320px) and (max-width: 991px) {
  .homes-filter-labels-inner .filter-label {
    display: none; } }

@media (min-width: 320px) and (max-width: 991px) {
  .homes-filter-labels-inner.open {
    order: 2;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 10px;
    padding: 15px;
    padding-top: 25px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .homes-filter-labels-inner.open {
    margin-top: 15px;
    padding: 20px;
    padding-top: 35px; } }

@media (min-width: 320px) and (max-width: 991px) {
  .homes-filter-labels-inner.open .filter-label {
    display: flex; } }

.homes-filter-labels input[type="checkbox"] {
  position: absolute;
  top: 2px;
  left: 2px; }
  .homes-filter-labels input[type="checkbox"] + label {
    position: relative;
    padding: 0 0 2rem 30px;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
    cursor: pointer; }
    @media (min-width: 1200px) {
      .homes-filter-labels input[type="checkbox"] + label {
        font-size: 1.77778rem; } }
    @media (min-width: 1400px) {
      .homes-filter-labels input[type="checkbox"] + label {
        font-size: 1.6rem; } }
    .homes-filter-labels input[type="checkbox"] + label:before {
      content: "";
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      left: 0;
      top: 1px;
      background-color: #ebebeb;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .homes-filter-labels input[type="checkbox"] + label:after {
      content: "\e92d";
      font-size: 9px;
      display: block;
      left: 5px;
      top: 7px;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .homes-filter-labels input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.blue input[type="checkbox"] + label {
  color: #007ea8; }
  .blue input[type="checkbox"] + label:after {
    color: #007ea8; }
  .blue input[type="checkbox"] + label:hover, .blue input[type="checkbox"] + label:focus {
    color: #0091c2; }

.blue input[type="checkbox"]:focus + label:before {
  border: 2px solid #007ea8; }

.green input[type="checkbox"] + label {
  color: #218721; }
  .green input[type="checkbox"] + label:after {
    color: #218721; }
  .green input[type="checkbox"] + label:hover, .green input[type="checkbox"] + label:focus {
    color: #269b26; }

.green input[type="checkbox"]:focus + label:before {
  border: 2px solid #218721; }

.orange input[type="checkbox"] + label {
  color: #ca4c1e; }
  .orange input[type="checkbox"] + label:after {
    color: #ca4c1e; }
  .orange input[type="checkbox"] + label:hover, .orange input[type="checkbox"] + label:focus {
    color: #de5523; }

.orange input[type="checkbox"]:focus + label:before {
  border: 2px solid #ca4c1e; }

.mobile-results {
  order: 3;
  margin-top: 10px; }
  @media (min-width: 768px) {
    .mobile-results {
      margin-top: 15px; } }
  @media (min-width: 992px) {
    .mobile-results {
      display: none; } }
  .mobile-results .btn {
    width: 100%; }

.blue .btn-colored {
  background-color: #007ea8;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  @media (min-width: 320px) {
    .blue .btn-colored {
      height: 10rem; } }
  @media (min-width: 480px) {
    .blue .btn-colored {
      height: 9.09091rem; } }
  @media (min-width: 545px) {
    .blue .btn-colored {
      height: 8.33333rem; } }
  @media (min-width: 768px) {
    .blue .btn-colored {
      height: 6.66667rem; } }
  @media (min-width: 992px) {
    .blue .btn-colored {
      height: 6.25rem; } }
  @media (min-width: 1200px) {
    .blue .btn-colored {
      height: 6rem; } }
  .blue .btn-colored:hover, .blue .btn-colored:focus {
    background-color: #0091c2; }

.green .btn-colored {
  background-color: #218721;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  @media (min-width: 320px) {
    .green .btn-colored {
      height: 10rem; } }
  @media (min-width: 480px) {
    .green .btn-colored {
      height: 9.09091rem; } }
  @media (min-width: 545px) {
    .green .btn-colored {
      height: 8.33333rem; } }
  @media (min-width: 768px) {
    .green .btn-colored {
      height: 6.66667rem; } }
  @media (min-width: 992px) {
    .green .btn-colored {
      height: 6.25rem; } }
  @media (min-width: 1200px) {
    .green .btn-colored {
      height: 6rem; } }
  .green .btn-colored:hover, .green .btn-colored:focus {
    background-color: #269b26; }

.orange .btn-colored {
  background-color: #ca4c1e;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  @media (min-width: 320px) {
    .orange .btn-colored {
      height: 10rem; } }
  @media (min-width: 480px) {
    .orange .btn-colored {
      height: 9.09091rem; } }
  @media (min-width: 545px) {
    .orange .btn-colored {
      height: 8.33333rem; } }
  @media (min-width: 768px) {
    .orange .btn-colored {
      height: 6.66667rem; } }
  @media (min-width: 992px) {
    .orange .btn-colored {
      height: 6.25rem; } }
  @media (min-width: 1200px) {
    .orange .btn-colored {
      height: 6rem; } }
  .orange .btn-colored:hover, .orange .btn-colored:focus {
    background-color: #de5523; }

#map-canvas {
  min-height: 400px;
  margin-top: 10px; }
  @media (min-width: 768px) {
    #map-canvas {
      margin-top: 15px; } }
  @media (min-width: 992px) {
    #map-canvas {
      margin-top: 0;
      border-radius: 0 2.5rem 2.5rem 0; } }

div[style*="img/map/pin-number-"] {
  line-height: 38px !important; }

.gm-style-iw-d {
  color: #5b5b5b;
  font-size: 15px; }
  @media (min-width: 1200px) {
    .gm-style-iw-d {
      font-size: 1.66667rem; } }
  @media (min-width: 1400px) {
    .gm-style-iw-d {
      font-size: 1.5rem; } }
  .gm-style-iw-d h4 {
    font-size: 16px;
    font-weight: 700; }
    @media (min-width: 1200px) {
      .gm-style-iw-d h4 {
        font-size: 1.77778rem; } }
    @media (min-width: 1400px) {
      .gm-style-iw-d h4 {
        font-size: 1.6rem; } }

.gm-style-iw {
  box-shadow: none !important;
  max-width: 300px !important; }
  @media (min-width: 320px) and (max-width: 991px) {
    .gm-style-iw {
      padding: 0 !important;
      max-height: 80vh !important; } }
  @media (min-width: 992px) {
    .gm-style-iw {
      max-width: 36rem !important; } }
  @media (min-width: 320px) and (max-width: 991px) {
    .gm-style-iw .gm-style-iw-d {
      max-height: 80vh !important;
      overflow: auto !important; } }
  .gm-style-iw .gm-ui-hover-effect {
    top: 4px !important;
    right: 0 !important; }
    .gm-style-iw .gm-ui-hover-effect img {
      display: none !important; }
    @media (min-width: 320px) and (max-width: 991px) {
      .gm-style-iw .gm-ui-hover-effect {
        background-color: #fff !important;
        opacity: 1 !important; } }
    .gm-style-iw .gm-ui-hover-effect:before {
      padding: 8px;
      content: "\e92c";
      font-size: 13px; }
    .gm-style-iw .gm-ui-hover-effect span {
      display: none !important; }
  .gm-style-iw .marker-detail-image {
    background-size: cover;
    padding-top: 45%; }
    @media (min-width: 992px) {
      .gm-style-iw .marker-detail-image {
        display: none; } }
  @media (min-width: 320px) and (max-width: 991px) {
    .gm-style-iw .marker-detail-link {
      padding: 0 15px;
      margin-top: 10px;
      display: inline-block; } }
  .gm-style-iw .marker-detail-link h4 {
    padding: 0;
    margin: 0; }
  .gm-style-iw .marker-detail-content,
  .gm-style-iw .marker-detail-phone,
  .gm-style-iw .marker-detail-labels {
    margin-top: 10px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .gm-style-iw .marker-detail-content,
      .gm-style-iw .marker-detail-phone,
      .gm-style-iw .marker-detail-labels {
        padding: 0 15px; } }
  @media (min-width: 320px) and (max-width: 991px) {
    .gm-style-iw .marker-detail-labels {
      padding-bottom: 15px; } }

.blue .gm-style-iw-d h4 {
  color: #007ea8; }

.blue .gm-ui-hover-effect:before {
  color: #007ea8; }

.green .gm-style-iw-d h4 {
  color: #218721; }

.green .gm-ui-hover-effect:before {
  color: #218721; }

.orange .gm-style-iw-d h4 {
  color: #ca4c1e; }

.orange .gm-ui-hover-effect:before {
  color: #ca4c1e; }

.big-body .article-detail {
  font-size: 1.9rem; }

.big-body .article-detail .perex {
  font-size: 2.1rem; }

@media (min-width: 320px) {
  .article-detail {
    font-size: 3rem; } }

@media (min-width: 480px) {
  .article-detail {
    font-size: 2.72727rem; } }

@media (min-width: 545px) {
  .article-detail {
    font-size: 2.5rem; } }

@media (min-width: 768px) {
  .article-detail {
    font-size: 2rem; } }

@media (min-width: 992px) {
  .article-detail {
    font-size: 1.875rem; } }

@media (min-width: 1200px) {
  .article-detail {
    font-size: 1.8rem; } }

@media (min-width: 320px) {
  .article-detail .perex {
    font-size: 3.6rem; } }

@media (min-width: 480px) {
  .article-detail .perex {
    font-size: 3.27273rem; } }

@media (min-width: 545px) {
  .article-detail .perex {
    font-size: 3rem; } }

@media (min-width: 768px) {
  .article-detail .perex {
    font-size: 2.4rem; } }

@media (min-width: 992px) {
  .article-detail .perex {
    font-size: 2.25rem; } }

@media (min-width: 1200px) {
  .article-detail .perex {
    font-size: 2.2rem; } }

@media (min-width: 320px) {
  .article-detail h1 {
    font-size: 4rem; } }

@media (min-width: 480px) {
  .article-detail h1 {
    font-size: 3.63636rem; } }

@media (min-width: 545px) {
  .article-detail h1 {
    font-size: 3.33333rem; } }

@media (min-width: 768px) {
  .article-detail h1 {
    font-size: 2.66667rem; } }

@media (min-width: 992px) {
  .article-detail h1 {
    font-size: 2.5rem; } }

@media (min-width: 1200px) {
  .article-detail h1 {
    font-size: 2.4rem; } }

.articles-box {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem; }

.article-item-box {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 1rem; }
  @media (min-width: 768px) {
    .article-item-box {
      flex: 0 0 33.3333%;
      max-width: 33.3333%; } }

.article-item {
  display: block;
  background-color: #fff;
  border-radius: 25px;
  text-align: center;
  padding: 2rem 2.5rem 4rem 2.5rem;
  color: #4c4c4c;
  transition: 0.3s ease all; }
  @media (min-width: 1400px) {
    .article-item {
      padding: 4rem 4.5rem; } }
  .article-item h3 {
    color: #007e41;
    padding: 0 0 1rem 0; }
  @media (min-width: 320px) {
    .article-item-perex {
      font-size: 3rem; } }
  @media (min-width: 480px) {
    .article-item-perex {
      font-size: 2.72727rem; } }
  @media (min-width: 545px) {
    .article-item-perex {
      font-size: 2.5rem; } }
  @media (min-width: 768px) {
    .article-item-perex {
      font-size: 2rem; } }
  @media (min-width: 992px) {
    .article-item-perex {
      font-size: 1.875rem; } }
  @media (min-width: 1200px) {
    .article-item-perex {
      font-size: 1.8rem; } }
  .article-item:hover, .article-item:focus {
    color: #fff;
    background-color: #007e41;
    text-decoration: none; }
    .article-item:hover h3, .article-item:focus h3 {
      color: #fff; }
  .article-item-date {
    font-size: 2.8rem; }
    @media (min-width: 480px) {
      .article-item-date {
        font-size: 2.54545rem; } }
    @media (min-width: 545px) {
      .article-item-date {
        font-size: 2.33333rem; } }
    @media (min-width: 768px) {
      .article-item-date {
        font-size: 1.86667rem; } }
    @media (min-width: 992px) {
      .article-item-date {
        font-size: 1.75rem; } }
    @media (min-width: 1200px) {
      .article-item-date {
        font-size: 1.55556rem; } }
    @media (min-width: 1400px) {
      .article-item-date {
        font-size: 1.4rem; } }

.gallery-widget-row {
  margin: 0 -1.5rem; }
  .gallery-widget-row [class^="col"] {
    padding: 1.5rem; }

.detail-photo-item {
  text-align: center; }
  .detail-photo-item-image {
    position: relative;
    height: 0;
    padding-bottom: 55%;
    width: 100%;
    overflow: hidden;
    text-align: center; }
  .detail-photo-item-bgr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .detail-photo-item-text {
    margin: 1rem 0 2rem 0; }
    .detail-photo-item-text h4 {
      transition: 0.3s ease all; }
  .detail-photo-item:focus, .detail-photo-item:hover {
    text-decoration: none; }
    .detail-photo-item:focus .detail-photo-item-bgr, .detail-photo-item:hover .detail-photo-item-bgr {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2); }

.banners-box-init:not(.slick-initialized) {
  display: flex;
  flex-wrap: wrap; }
  .banners-box-init:not(.slick-initialized) .banner-item-box {
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 768px) {
      .banners-box-init:not(.slick-initialized) .banner-item-box {
        flex: 0 0 33.3333%;
        max-width: 33.3333%; } }

.banners-box-init {
  margin: 0 -1rem; }

.banner-item-box {
  padding: 1rem; }

.banner-item {
  height: 100%;
  width: 100%;
  text-align: center;
  color: #4c4c4c;
  overflow: hidden; }
  @media (min-width: 320px) {
    .banner-item {
      font-size: 3rem; } }
  @media (min-width: 480px) {
    .banner-item {
      font-size: 2.72727rem; } }
  @media (min-width: 545px) {
    .banner-item {
      font-size: 2.5rem; } }
  @media (min-width: 768px) {
    .banner-item {
      font-size: 2rem; } }
  @media (min-width: 992px) {
    .banner-item {
      font-size: 1.875rem; } }
  @media (min-width: 1200px) {
    .banner-item {
      font-size: 1.8rem; } }
  .banner-item h3 {
    color: #007e41;
    margin: 3rem 0 1rem 0;
    padding: 0; }
  .banner-item-image-box {
    position: relative;
    height: 260px;
    overflow: hidden;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff; }
    @media (min-width: 768px) {
      .banner-item-image-box {
        height: 24rem; } }
    .banner-item-image-box.dark-background {
      background-color: #003e69; }
    .banner-item-image-box img {
      max-width: 23rem;
      transition: 0.3s ease all; }
  .banner-item-image {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: 0.3s ease all; }

a.banner-item:hover, a.banner-item:focus {
  text-decoration: none;
  color: #4c4c4c; }
  a.banner-item:hover .banner-item-image,
  a.banner-item:hover img, a.banner-item:focus .banner-item-image,
  a.banner-item:focus img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }

.testimonial {
  text-align: center;
  margin-top: 10px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60px, white 60px, white 100%); }
  .testimonial-image {
    border-radius: 50%;
    overflow: hidden;
    height: 120px;
    width: 120px;
    margin: 0 auto 4rem auto; }
  .testimonial-text {
    max-width: 118rem;
    margin: 0 auto;
    padding-bottom: 6rem; }
    .testimonial-text-name {
      display: inline-block;
      margin-top: 1.5rem; }

.title-w-separator {
  position: relative;
  display: block !important;
  padding-top: 1rem !important;
  padding-bottom: 0.5rem !important; }
  .title-w-separator:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    display: block; }

.blue .title-w-separator:before {
  background: #5cd6ff; }

.green .title-w-separator:before {
  background: #7cdf7c; }

.orange .title-w-separator:before {
  background: #f2bca8; }

:focus {
  outline: 1px !important;
  outline-offset: 1px !important; }

@media (min-width: 545px) {
  .homes-box {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem; } }

.service-item-content-labels li:before {
  display: none; }

.home-box {
  padding: 1rem 0; }
  @media (min-width: 545px) {
    .home-box {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 1rem; } }
  @media (min-width: 992px) {
    .home-box {
      flex: 0 0 33.333%;
      max-width: 33.333%;
      padding: 1rem; } }

.home-item {
  border-radius: 25px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 20px 70px rgba(41, 41, 41, 0.07);
  height: 100%; }
  .home-item-top {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -1px;
    color: white;
    padding: .7rem 1.5rem;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 700;
    transition: 0.3s ease opacity; }
    @media (min-width: 320px) {
      .home-item-top {
        font-size: 2.8rem; } }
    @media (min-width: 480px) {
      .home-item-top {
        font-size: 2.54545rem; } }
    @media (min-width: 545px) {
      .home-item-top {
        font-size: 2.33333rem; } }
    @media (min-width: 768px) {
      .home-item-top {
        font-size: 1.86667rem; } }
    @media (min-width: 992px) {
      .home-item-top {
        font-size: 1.75rem; } }
    @media (min-width: 1200px) {
      .home-item-top {
        font-size: 1.55556rem; } }
    @media (min-width: 1400px) {
      .home-item-top {
        font-size: 1.4rem; } }
  .home-item a {
    text-decoration: none; }
    @media (min-width: 320px) {
      .home-item a {
        font-size: 2.8rem; } }
    @media (min-width: 480px) {
      .home-item a {
        font-size: 2.54545rem; } }
    @media (min-width: 545px) {
      .home-item a {
        font-size: 2.33333rem; } }
    @media (min-width: 768px) {
      .home-item a {
        font-size: 1.86667rem; } }
    @media (min-width: 992px) {
      .home-item a {
        font-size: 1.75rem; } }
    @media (min-width: 1200px) {
      .home-item a {
        font-size: 1.6rem; } }
  .home-item-image {
    position: relative;
    display: block;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    min-height: 50rem;
    text-decoration: none; }
    @media (min-width: 768px) {
      .home-item-image {
        min-height: 26rem; } }
    .home-item-image:before {
      content: '';
      position: absolute;
      transition: 0.3s ease opacity;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      z-index: 2; }
    .home-item-image .home-item-hover-link {
      display: none; }
  .home-item-content {
    padding: 0 4rem; }
    .home-item-content h3 {
      padding: 3rem 0 0.5rem 0;
      margin: 0;
      display: inline-block; }
      @media (min-width: 320px) {
        .home-item-content h3 {
          font-size: 3.6rem; } }
      @media (min-width: 480px) {
        .home-item-content h3 {
          font-size: 3.27273rem; } }
      @media (min-width: 545px) {
        .home-item-content h3 {
          font-size: 3rem; } }
      @media (min-width: 768px) {
        .home-item-content h3 {
          font-size: 2.4rem; } }
      @media (min-width: 992px) {
        .home-item-content h3 {
          font-size: 2.3rem; } }
      .home-item-content h3:hover, .home-item-content h3:focus {
        text-decoration: underline; }
    @media (min-width: 320px) {
      .home-item-content-address {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .home-item-content-address {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .home-item-content-address {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .home-item-content-address {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .home-item-content-address {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .home-item-content-address {
        font-size: 1.8rem; } }
    .home-item-content-links {
      line-height: 2;
      padding: 2rem 0;
      border-bottom: 1px solid #cdcdcd; }
      @media (min-width: 1400px) {
        .home-item-content-links {
          column-count: 2;
          column-gap: 1rem; } }
      .home-item-content-links a {
        font-weight: 700;
        white-space: nowrap; }
        .home-item-content-links a:before {
          display: inline-block;
          color: #cdcdcd;
          text-decoration: underline;
          margin-right: 1.8rem;
          width: 10px; }
        .home-item-content-links a:hover {
          text-decoration: underline; }
          .home-item-content-links a:hover:before {
            text-decoration: underline; }
        .home-item-content-links a:before, .home-item-content-links a:hover:before {
          text-decoration: none; }
  .home-item-labels {
    font-weight: 700;
    padding: 2rem 0; }
    .home-item-labels button {
      font-weight: 700;
      cursor: pointer;
      background: transparent;
      border: none;
      outline: 0;
      padding: 0; }

.home-item-properties,
.home-item-properties-hover {
  height: 100%;
  width: 100%;
  z-index: 2;
  padding: 3rem;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  position: relative; }
  @media (min-width: 1200px) {
    .home-item-properties,
    .home-item-properties-hover {
      padding: 2rem 1.5rem; } }
  @media (min-width: 1200px) {
    .home-item-properties,
    .home-item-properties-hover {
      padding: 2rem 1.5rem; } }
  @media (min-width: 1500px) {
    .home-item-properties,
    .home-item-properties-hover {
      padding: 3rem 3rem; } }
  .home-item-properties .home-item-property,
  .home-item-properties-hover .home-item-property {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 64px;
    width: 64px;
    padding: 0.5rem;
    margin: 0 1rem 1rem 0;
    border-radius: 5px;
    background-color: rgba(41, 41, 41, 0.6);
    font-weight: 700;
    line-height: 10px;
    line-height: 3rem; }
    @media (min-width: 320px) {
      .home-item-properties .home-item-property,
      .home-item-properties-hover .home-item-property {
        font-size: 2.6rem; } }
    @media (min-width: 480px) {
      .home-item-properties .home-item-property,
      .home-item-properties-hover .home-item-property {
        font-size: 2.36364rem; } }
    @media (min-width: 545px) {
      .home-item-properties .home-item-property,
      .home-item-properties-hover .home-item-property {
        font-size: 2.16667rem; } }
    @media (min-width: 768px) {
      .home-item-properties .home-item-property,
      .home-item-properties-hover .home-item-property {
        font-size: 2rem; } }
    .home-item-properties .home-item-property span,
    .home-item-properties-hover .home-item-property span {
      font-size: 0;
      font-weight: 400;
      display: flex;
      flex-shrink: 1; }
      .home-item-properties .home-item-property span:before,
      .home-item-properties-hover .home-item-property span:before {
        font-size: 30px; }
  .home-item-properties .home-item-hover-link,
  .home-item-properties-hover .home-item-hover-link {
    display: none; }

.home-item-properties {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 100%; }

.home-item-properties-hover {
  display: none;
  visibility: hidden;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap; }
  .home-item-properties-hover .home-item-property {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    height: auto;
    background-color: transparent;
    line-height: 1.2;
    padding: 0;
    margin: 0 0 2rem 0; }
    @media (min-width: 768px) {
      .home-item-properties-hover .home-item-property {
        margin: 0 0 1.5rem 0; } }
    @media (min-width: 320px) {
      .home-item-properties-hover .home-item-property {
        font-size: 3rem; } }
    @media (min-width: 480px) {
      .home-item-properties-hover .home-item-property {
        font-size: 2.72727rem; } }
    @media (min-width: 545px) {
      .home-item-properties-hover .home-item-property {
        font-size: 2.5rem; } }
    @media (min-width: 768px) {
      .home-item-properties-hover .home-item-property {
        font-size: 2rem; } }
    .home-item-properties-hover .home-item-property span {
      margin-right: 1rem; }
      @media (min-width: 320px) {
        .home-item-properties-hover .home-item-property span {
          font-size: 3rem; } }
      @media (min-width: 480px) {
        .home-item-properties-hover .home-item-property span {
          font-size: 2.72727rem; } }
      @media (min-width: 545px) {
        .home-item-properties-hover .home-item-property span {
          font-size: 2.5rem; } }
      @media (min-width: 768px) {
        .home-item-properties-hover .home-item-property span {
          font-size: 2rem; } }
      .home-item-properties-hover .home-item-property span:before {
        margin-right: 0.5rem;
        display: inline-block;
        vertical-align: middle;
        line-height: 23px; }
  .home-item-properties-hover .home-item-properties-bottom,
  .home-item-properties-hover .home-item-property-bottom {
    display: none; }
  @media (min-width: 768px) {
    .home-item-properties-hover {
      display: flex; } }

.home-item-properties-hover {
  visibility: hidden; }

.home-item-properties-bottom {
  display: none; }

.home-item-image:hover .home-item-properties {
  display: none; }

.home-item-image:hover .home-item-properties-hover {
  visibility: visible; }

.home-item-image:hover:before {
  opacity: 0.8;
  transition: 0.3s ease opacity; }

.home-item-image:hover .home-item-top {
  opacity: 0; }

@media (min-width: 768px) {
  .long-list {
    display: block; } }

@media (min-width: 768px) {
  .long-list .home-box {
    max-width: 100%; } }

@media (min-width: 768px) {
  .long-list .home-item {
    display: flex;
    flex-wrap: wrap; }
    .long-list .home-item-image {
      flex: 0 0 20rem;
      max-width: 20rem;
      min-height: 12rem;
      height: auto;
      padding-bottom: 0;
      flex-shrink: 0;
      max-height: 120px; } }
    @media (min-width: 768px) and (min-width: 768px) {
      .long-list .home-item-image:hover .home-item-hover-link, .long-list .home-item-image:focus .home-item-hover-link {
        display: block;
        position: absolute;
        white-space: nowrap;
        color: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 700; } }

@media (min-width: 768px) {
    .long-list .home-item-content {
      max-width: calc(100% - 20rem);
      display: flex;
      flex-wrap: wrap;
      padding: 2rem 0 0 0;
      flex-grow: 1;
      flex-shrink: 1;
      padding: 2rem 1rem 0 1rem; } }
    @media (min-width: 768px) and (min-width: 992px) {
      .long-list .home-item-content {
        margin: 0 4rem;
        padding: 2rem 0 0 0;
        border-bottom: 1px solid #cdcdcd; } }

@media (min-width: 768px) {
      .long-list .home-item-content h3 {
        padding-top: 0; }
      .long-list .home-item-content-header {
        flex: 0 0 50%;
        max-width: 50%; }
      .long-list .home-item-content-links {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 0 1rem 0;
        border: none;
        column-gap: 1rem;
        column-fill: balance; }
    .long-list .home-item .home-item-properties-hover {
      display: none; }
    .long-list .home-item .home-item-properties-bottom {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 1rem 4rem;
      border-top: 1px solid #cdcdcd; } }
    @media (min-width: 768px) and (min-width: 992px) {
      .long-list .home-item .home-item-properties-bottom {
        border: none; } }

@media (min-width: 768px) {
      .long-list .home-item .home-item-properties-bottom:before {
        display: none; }
      .long-list .home-item .home-item-properties-bottom .home-item-property-bottom {
        display: inline-flex;
        height: auto;
        font-weight: 400; }
        .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span {
          font-size: 3rem;
          margin-right: 5rem; } }
        @media (min-width: 768px) and (min-width: 480px) {
          .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span {
            font-size: 2.72727rem; } }
        @media (min-width: 768px) and (min-width: 545px) {
          .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span {
            font-size: 2.5rem; } }
        @media (min-width: 768px) and (min-width: 768px) {
          .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span {
            font-size: 2rem; } }
        @media (min-width: 768px) and (min-width: 992px) {
          .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span {
            font-size: 1.875rem; } }
        @media (min-width: 768px) and (min-width: 1200px) {
          .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span {
            font-size: 1.66667rem; } }
        @media (min-width: 768px) and (min-width: 1400px) {
          .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span {
            font-size: 1.5rem; } }

@media (min-width: 768px) {
          .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span:before {
            font-size: 6rem;
            font-weight: 700;
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.5rem;
            color: #cdcdcd; } }
          @media (min-width: 768px) and (min-width: 480px) {
            .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span:before {
              font-size: 5.45455rem; } }
          @media (min-width: 768px) and (min-width: 545px) {
            .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span:before {
              font-size: 5rem; } }
          @media (min-width: 768px) and (min-width: 768px) {
            .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span:before {
              font-size: 4rem; } }
          @media (min-width: 768px) and (min-width: 992px) {
            .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span:before {
              font-size: 3.75rem; } }
          @media (min-width: 768px) and (min-width: 1200px) {
            .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span:before {
              font-size: 3.33333rem; } }
          @media (min-width: 768px) and (min-width: 1400px) {
            .long-list .home-item .home-item-properties-bottom .home-item-property-bottom span:before {
              font-size: 3rem; } }

@media (min-width: 768px) {
    .long-list .home-item-property {
      display: none; }
    .long-list .home-item-labels {
      display: none; } }

.tooltip {
  font-family: "proxima-nova", sans-serif; }
  @media (min-width: 320px) {
    .tooltip {
      font-size: 2.6rem; } }
  @media (min-width: 480px) {
    .tooltip {
      font-size: 2.36364rem; } }
  @media (min-width: 545px) {
    .tooltip {
      font-size: 2.16667rem; } }
  @media (min-width: 768px) {
    .tooltip {
      font-size: 1.73333rem; } }
  @media (min-width: 992px) {
    .tooltip {
      font-size: 1.625rem; } }
  @media (min-width: 1200px) {
    .tooltip {
      font-size: 1.5rem; } }
  .tooltip .tooltip-inner {
    padding: 1rem 2rem;
    background-color: rgba(82, 82, 82, 0.98); }

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  content: "";
  border-width: 10px 10px 0;
  transform: translate(-25%, 0);
  border-top-color: rgba(82, 82, 82, 0.98); }

.blue .home-item-image:before {
  background: #007ea8; }

.blue button {
  color: #007ea8; }

.blue .home-item-top {
  background: #007ea8; }

.green .home-item-image:before {
  background: #218721; }

.green button {
  color: #218721; }

.green .home-item-top {
  background: #218721; }

.orange .home-item-image:before {
  background: #ca4c1e; }

.orange button {
  color: #ca4c1e; }

.orange .home-item-top {
  background: #ca4c1e; }

.home-item-content-links-phone:before {
  content: '\e958'; }

.home-item-content-links-exploration:before {
  content: '\e900';
  font-size: 11px;
  transform: translate(-3px, 0); }

.home-item-content-links-url:before {
  content: '\e928'; }

.home-item-content-links-apply:before {
  content: '\ea77'; }

.home-people:before {
  content: '\e933'; }

.home-home:before {
  content: '\e934'; }

.home-plus:before {
  content: '\e935'; }

.home-nitting:before {
  content: '\e936'; }

.home-feather:before {
  content: '\e937'; }

.home-care:before {
  content: '\e938'; }

.home-shelter:before {
  content: '\e939'; }

.home-dzr:before {
  content: '\e93a'; }

.home-calendar:before {
  content: '\e93b'; }

.home-house:before {
  content: '\e93c'; }

.home-family:before {
  content: '\e93d'; }

.home-message:before {
  content: '\e93f'; }

.home-money:before {
  content: '\e940'; }
