
$custom-slider: true;
$custom-slider-dots-matgin: 1.5rem 0;

// arrows
$slick-arrow-background: $main-color;
$slick-arrow-opacity: 1;
$slick-arrow-color: #fff;
$slick-arrow-width: 40px;
$slick-arrow-height: 40px;
$slick-arrow-font-size: 14px;

// arrow next
$slick-arrow-next-right: -8px;
$slick-arrow-next-ico: "\e92b";

// arrow prev
$slick-arrow-prev-left: -8px;
$slick-arrow-prev-ico: "\e92b";

// dots
$slick-dosts-width: 3.6rem;
$slick-dots-height: 3px;
$slick-dots-background: $main-color;
$slick-dots-active-background: $main-color;
$slick-dots-margin-right: 10px;

.slick-dots{
  display: flex;
  li{
    padding:0;
    margin:0 $slick-dots-margin-right 0 0;
    button{
      width: $slick-dosts-width;
      height: $slick-dots-height;
      background: $slick-dots-background;
      padding: 0;
      border: none;
      font-size: 0;
      outline: 0;
      border-radius: 5px;
    }
    &:before{
      display: none;
    }
    &.slick-active,
    &.active{
      button{
        background: $slick-dots-active-background;
      }
    }
  }
}

.slick-arrow{
  width: $slick-arrow-width/1.5;
  height: $slick-arrow-height/1.5;
  border:none;
  font-size: 0;
  position: absolute;
  top: 130px;
  z-index: 9999;
  cursor: pointer;
  outline: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $slick-arrow-color;
  border-radius: 50%;
  transition: 0.3s;
  background-color: $slick-arrow-background;
  @include media(md) {
    width: $slick-arrow-width;
    height: $slick-arrow-height;
    top: 12rem;
  }
  &:hover, &:focus {
    background: lighten($slick-arrow-background,5);
  }
  &:before{
    font-size: $slick-arrow-font-size/1.5;
    @include media(md) {
      font-size: $slick-arrow-font-size;
    }
  }
  &.slick-next{
      right: $slick-arrow-next-right;
    &:before{
      content: $slick-arrow-next-ico;
    }
  }
  &.slick-prev{
      left: $slick-arrow-prev-left;
      transform: rotate(180deg);
      &:before{
        content: $slick-arrow-prev-ico;
      }
    }
  }
  

// custom slider
@if$custom-slider{
  .custom-slider{
    display: flex;
    overflow: hidden;
    .custom-slider-item{
      width: 100%;
      min-width: 100%;
    }
    &.slick-slider{
      display: block;
      overflow: visible;
      .custom-slider-item{
        min-width: 1px;
      }
    }
  }
  .custom-slider-pager{
    .slick-dots{
      margin: $custom-slider-dots-matgin;
      justify-content: center;
    }  
  }
}
