
$px-values: (5px, 10px, 15px, 20px, 25px, 30px, 35px, 40px, 45px, 50px, 55px, 60px, 65px, 70px, 75px, 80px, 85px, 90px, 95px, 100px);

// bootstrap
$main-left: 15px;
$main-right: 15px;
$main-left-md: 6rem;
$main-right-md: 6rem;

// container
$container-max-width: 172rem;
$container-padding-left: $main-left;
$container-padding-right: $main-right;
$container-padding-left-md: $main-left-md;
$container-padding-right-md: $main-right-md;

// fluid container
$fluid-container-padding-left: $main-left;
$fluid-container-padding-right: $main-right;
$fluid-container-padding-left-md: $main-left-md;
$fluid-container-padding-right-md: $main-right-md;
$fluid-container-padding-left-xl: 12rem;
$fluid-container-padding-right-xl: 12rem;


// row
$row-margin-left: (-$main-left);
$row-margin-right: (-$main-right);
$row-margin-left-md: (-$main-left-md);
$row-margin-right-md: (-$main-right-md);

// col
$col-padding-left: $main-left;
$col-padding-right: $main-right;
$col-padding-left-md: $main-left-md;
$col-padding-right-md: $main-right-md;

// typography
$font-family: 'proxima-nova', sans-serif;
$font-size: 18px;

// perex
$font-size-perex: 23px;
$min-font-size-perex: 15px;
$line-height-perex: 30px;
$min-line-height-perex: 25px;

// small text
$font-size-small: 14px;
$min-font-size-small: 14px;
$line-height-small: 25px;
$min-line-height-small: 25px;

// main colors
$color-link: #007e41;
$main-color: #007e41;
$second-color: #4c4c4c;
$color-line: #f1f1f1;
$base-black: #4c4c4c;
$base-white: #fff;
$base-grey: #cdcdcd;
$grey-background: #f2fbf8;
$footer-color: #003e69;

//theme colors
$service-orange: #ca4c1e;
$service-green: #218721;
$service-blue: #007ea8;

// button primary
$btn-primary-background: transparent;
$btn-primary-color: $main-color;

// button default
$btn-default-background: transparent;
$btn-default-color: $second-color;

// ul, li
$ul-bullet: $main-color;
$ol-number: $main-color;

// table
$table-even-background: #f5f5f5;
$table-tr-border: #f1f1f1;
$respo-table-break: "md";

// menu
$hamburger-width: 6rem;
$menu-break: "xl";

// form
$color-placeholder: #4c4c4c;

// inputs
$border-color: #f1f1f1;
$input-color: $base-black;
$input-background: #f5f5f5;
$focus-border-color: $main-color;
$input-height: 50px;
$textarea-height: $input-height * 3;

// selectric
$select-background: $input-background;
$select-color: $input-color;
$select-border: $input-background;
$select-border-focus: $main-color;
$select-arrow-color: $main-color;
$select-arrow-code: "\e901";
$select-use-triangle: 0;
$select-height: $input-height;

// datepicker
$datepick-today-background: $second-color;
$datepick-today-color:$base-white;
$datepick-active-background: $main-color;
$datepick-active-color: $base-white;

// upload
$upload-background: $main-color;
$upload-color: $base-white;
$file-name-background: $second-color;
$file-name-color: $base-black;

// form radio
$radio-background: #eeeff4;
$radio-border: #eeeff4;
$radio-active-border: $main-color;
$radio-after-background: $main-color;

// form checkbox
$checkbox-background: #eeeff4;
$checkbox-border: #eeeff4;
$checkbox-active-border: $main-color;
$checkbox-after-background: $main-color;

// form error
$error-input-border: #d40511;
$error-label-color: #d40511;
$error-blink-start: #fff;
$error-blink-final: #fec7ca;
$use-blink-error: 1;

//scale ratios
$scale-base-size: 10px;
$scale-big-size: 11px;

$scale-ratio-xxs: 0.5;
$scale-ratio-xs: 0.55;
$scale-ratio-sm: 0.6;
$scale-ratio-md: 0.75;
$scale-ratio-lg:  0.8;
$scale-ratio-xl: 0.9;
$scale-ratio-xxl: 1;
