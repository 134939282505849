@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/themes.scss";
@import "_variables.scss";

.homes-box {
  @include media(sm) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
  }
}

.service-item-content-labels li{
  &:before{
    display: none;
  }
}

.home-box {
  padding: 1rem 0;
  @include media(sm) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 1rem;
  }
  @include media(lg) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    padding: 1rem;
  }
}

.home-item {
  border-radius: 25px;
  overflow: hidden;
  background-color: $base-white;
  box-shadow: 0 20px 70px rgba(41, 41, 41, 0.07);
  height: 100%;
  &-top{
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -1px;
    color: white;
    padding: .7rem 1.5rem;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 700;
    transition: 0.3s ease opacity;
    @include pux-scale-with-min('font-size', 14px, 14px);
  }
  a {
    @include pux-scale-with-min('font-size', 16px, 14px);
    text-decoration: none;
  }
  &-image {
    position: relative;
    display: block;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    min-height: 50rem;
    text-decoration: none;
    @include media(md) {
      min-height: 26rem;
    }
    &:before {
      content: '';
      position: absolute;
      transition: 0.3s ease opacity;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      z-index: 2;
    }
    .home-item-hover-link {
      display: none;
    }
  }

  &-content {
    padding: 0 4rem;
    h3 {
      @include pux-scale-with-min('font-size', 23px, 18px);
      padding: 3rem 0 0.5rem 0;
      margin: 0;
      display: inline-block;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
    &-address {
      @include pux-scale-with-min('font-size', 18px, 16px);
    }
    &-links {
      line-height: 2;
      padding: 2rem 0;
      border-bottom: 1px solid $base-grey;
      @include media (xxl) {
        column-count: 2;
        column-gap: 1rem;
      }
      a {
        font-weight: 700;
        white-space: nowrap;
        &:before {
          display: inline-block;
          color: $base-grey;
          text-decoration: underline;
          margin-right: 1.8rem;
          width: 10px;
        }
        &:hover {
              text-decoration: underline;
          &:before {
            text-decoration: underline;
          }
        }
        &:before,
        &:hover:before {
          text-decoration: none;
        }
      }
    }
  }

  &-labels {
    font-weight: 700;
    padding: 2rem 0;
    button {
      font-weight: 700;
      cursor: pointer;
      background: transparent;
      border: none;
      outline: 0;
      padding: 0;
    }
  }
}

//Properties (hover) -------------------------------------------



.home-item-properties,
.home-item-properties-hover {
  height: 100%;
  width: 100%;
  z-index: 2;
  padding: 3rem;
  color: $base-white;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
  @include media(xl) {
    padding: 2rem 1.5rem;
  }
  @include media(xl) {
    padding: 2rem 1.5rem;
  }
  @include media(xxxl) {
    padding: 3rem 3rem;
  }


  .home-item-property {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 64px;
    width: 64px;
    padding: 0.5rem;
    margin: 0 1rem 1rem 0;
    border-radius: 5px;
    background-color: rgba(41, 41, 41, 0.6);
    font-weight: 700;
    line-height: 10px;
    line-height: 3rem;
    @include pux-scale-with-min('font-size', 20px, 13px);
    span {
      font-size: 0;
      font-weight: 400;
      display: flex;
      flex-shrink: 1;
      &:before {
        font-size: 30px;
      }
    }
  }
  .home-item-hover-link {
    display: none;
  }
}

.home-item-properties {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
}

.home-item-properties-hover {
  display: none;
  visibility: hidden;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  .home-item-property {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    height: auto;
    background-color: transparent;
    line-height: 1.2;
    padding: 0;
    margin: 0 0 2rem 0;
    @include media(md) {
      margin: 0 0 1.5rem 0;
    }
    @include pux-scale-with-min('font-size', 20px, 15px);
    span {
      @include pux-scale-with-min('font-size', 20px, 15px);
      margin-right: 1rem;
      &:before {
        margin-right: 0.5rem;
        display: inline-block;
        vertical-align: middle;
        line-height: 23px;
      }
    }
  }
  .home-item-properties-bottom,
  .home-item-property-bottom {
    display: none;
  }
  @include media(md) {
    display: flex;
  }
}
.home-item-properties-hover {
  visibility: hidden;
}
.home-item-properties-bottom {
  display: none;
}

.home-item-image:hover {
  .home-item-properties {
    display: none;
  }
  .home-item-properties-hover {
    visibility: visible;
  }
  &:before {
    opacity: 0.8;
    transition: 0.3s ease opacity;
  }
  .home-item-top{
    opacity: 0;
  }

}

//Long list ----------------------------------------------------

.long-list {
  @include media(md) {
    display: block;
  }
}
.long-list .home-box {
  @include media(md) {
    max-width: 100%;
  }
}

.long-list .home-item {
  @include media(md) {
    display: flex;
    flex-wrap: wrap;
    &-image {
      flex: 0 0 20rem;
      max-width: 20rem;
      min-height: 12rem;
      height: auto;
      padding-bottom: 0;
      flex-shrink: 0;
      max-height: 120px;
      &:hover, &:focus {
        .home-item-hover-link{
          @include media(md) {
            display: block;
            position: absolute;
            white-space: nowrap;
            color: $base-white;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: 700;
          }
        }
      }
    }
    &-content {
      max-width: calc(100% - 20rem);
      display: flex;
      flex-wrap: wrap;
      padding: 2rem 0 0 0;
      flex-grow: 1;
      flex-shrink: 1;
      padding: 2rem 1rem 0 1rem;

      @include media(lg) {
        margin: 0 4rem;
        padding: 2rem 0 0 0;
        border-bottom: 1px solid $base-grey;
      }
      h3 {
        padding-top: 0;
      }
      &-header {
        flex: 0 0 50%;
        max-width: 50%;
      }
      &-links {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 0 1rem 0;
        border: none;
        column-gap: 1rem;
        column-fill: balance;

      }
    }

    .home-item-properties-hover {
     display: none;
    }

    .home-item-properties-bottom {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 1rem 4rem;
      border-top: 1px solid $base-grey;
      @include media(lg) {
        border: none;
      }
      &:before {
        display: none;
      }
      .home-item-property-bottom {
        display: inline-flex;
        height: auto;
        font-weight: 400;
        span {
          @include pux-static-size('font-size', 15px);
          margin-right: 5rem;
          &:before {
            @include pux-static-size('font-size', 30px);
            font-weight: 700;
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.5rem;
            color: $base-grey;
          }
        }
      }
    }
    &-property {
      display: none;
    }
    &-labels {
      display: none;
    }
  }

}

//Tooltips -----------------------------------------------------

.tooltip {
  @include pux-scale-with-min('font-size', 15px, 13px);
  font-family: $font-family;
  .tooltip-inner {
    padding: 1rem 2rem;
    background-color: rgba(82, 82, 82, 0.98);
  }
}

.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  content: "";
  border-width: 10px 10px 0;
  transform: translate(-25%, 0);
  border-top-color: rgba(82, 82, 82, 0.98);
}

//Theme ---------------------------------------------------------

@include themify($themes) {
  .home-item-image:before {
    background: themed('theme-color');
  }
  button {
    color: themed('theme-color');
  }
  .home-item-top{
    background: themed('theme-color');
  }
}


//Icons --------------------------------------------------------


.home-item-content-links-phone:before {
  content: '\e958';
}

.home-item-content-links-exploration:before {
  content: '\e900';
  font-size: 11px;
  transform: translate(-3px, 0);
}

.home-item-content-links-url:before {
  content: '\e928';
}

.home-item-content-links-apply:before {
  content: '\ea77';
}


.home-people:before {
  content: '\e933';
}

.home-home:before {
  content: '\e934';
}

.home-plus:before {
  content: '\e935';
}

.home-nitting:before {
  content: '\e936';
}

.home-feather:before {
  content: '\e937';
}

.home-care:before {
  content: '\e938';
}

.home-shelter:before {
  content: '\e939';
}

.home-dzr:before {
  content: '\e93a';
}

.home-calendar:before {
  content: '\e93b';
}

.home-house:before {
  content: '\e93c';
}

.home-family:before {
  content: '\e93d';
}

.home-message:before {
  content: '\e93f';
}

.home-money:before {
  content: '\e940';
}
