
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";
@import "modules/pux-slider.scss";
@import "_mixins/themes.scss";

.pux-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
    .pux-container{
      padding-left:0;
      padding-right:0;
    }
}

.pux-container{
    .row{
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;
        @include media(md){
          margin-left: $row-margin-left-md;
          margin-right: $row-margin-right-md;
        }
    }
}

.pux-container{
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto{
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;
        @include media(md){
          padding-left: $col-padding-left-md;
          padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container{
    padding-left: $fluid-container-padding-left;
    padding-right: $fluid-container-padding-right;
    @include media(md){
      padding-left: $fluid-container-padding-left-md;
      padding-right: $fluid-container-padding-right-md;
    }
    @include media(xl){
      padding-left: $fluid-container-padding-left-xl;
      padding-right: $fluid-container-padding-right-xl;
    }
}

.small-container {
  max-width: 70rem;
  margin: 0 auto;
}

header.scrolled {
    .header-logo {
      a {
        //@include pux-scale-with-min ('font-size', 18px, 16px);
        font-size: 18px;
      }
      img {
        height: 3.5rem !important;
      }
    }
}

.link-full{
  .link-full-social{
    margin-left:2rem;
    padding-left:2rem;
    border-left: 1px solid rgba(76, 76, 76, 0.25);
    display: none;
    @include media(xxl){
      display: flex;
      align-items: center;
    }
  }
}

header{
  position: fixed;
  width: 100%;
  z-index: 99992;
  background-color: $base-white;
  //box-shadow: 0 1px #dedede;
  height: 70px;
  .header {
    height: 70px;
  }
  &.scrolled, &.scrolled .header {
    height: 60px;
  }
  @include media($menu-break){
    height: 10rem;
    .header {
      height: 10rem;
    }
    &.scrolled {
      height: 7rem;
      .header {
        height: 7rem;
      }
    }
  }

  @include transition;
  .header{
    @include flex-block;
    @include align-items(center);
    @include justify-content(space-between);
    transition: 0.3s ease all;
    width: 100%;
    &-logo{
        @include flex-block;
        @include align-items(center);
        line-height: 1.2;
      a{
        display:inline-block;
        //@include pux-scale-with-min("font-size", 20px, 15px);
        font-size: 20px;
        text-transform: uppercase;
        color: $base-black;
        &.link-full {
          display: none;
          @include media(md) {
            display: flex;
          }
        }
        &.link-respo {
          display: inline-block;
          @include media(md) {
            display: none;
          }
        }
        &:hover,
        &:focus {
          text-decoration: none;
        }
        span{
          font-weight: 700;
        }
        img{
          height: 4.5rem !important;
          max-width: none;
          @include transition;
          margin-left: 1rem;
          @include media(md) {
            border-right: 1px solid rgba(76, 76, 76, 0.25);
            padding-right: 2rem;
            margin-right: 2rem;
          }
        }
      }
    }
    &-menu{
      //padding: 1rem 0;
      display:none;
      position: absolute;
      z-index: 77;
      left:0;
      top: 100%;
      width: 100%;
      @include calc("max-height", "100vh - 5rem");
      transition: 0.3s ease all;
      background-color: $base-white;
      @include media($menu-break){
        @include flex-block;
        position: static;
        background: none;
        width: auto;
        height: 100%;
        overflow: hidden;
      }
      ul{
        margin:0;
        padding:0;
        list-style: none;
        @include media($menu-break){
          @include flex-block;
          justify-content: flex-end;
          width: 100%;
        }
        li{
          display: block;
          position: relative;
          margin: 2rem 0;
          @include media($menu-break){
            margin:0;
            @include flex-block;
            @include align-items(center);
            @include justify-content(center);
          }
          @include media(xxxl){
            padding:0 1rem;
          }
          &:before,
          a:before {
            @include media($menu-break) {
              content :'';
              opacity: 0;
              transition: 0.3s ease opacity;
            }
          }
          &.active,
          a:hover {
            &:before {
              content :'';
              position: absolute;
              bottom: 50%;
              left: 15px;
              transform: translate(-50%, 50%);
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 11px 0 11px 18px;
              display: none;
              @include media(xxl){
                display: block;
              }
            }
            @include media($menu-break) {
              &:before {
                content :'';
                bottom: 0;
                left: 50%;
                transform: translate(-50%,0);
                border-width: 0 18px 18px 18px;
                opacity: 1;
              }

            }
          }
          @include themify($themes) {
            a {
              color: themed('theme-color');
            }
            &.active,
            a:hover
            {
              &:before {
                border-color: transparent transparent transparent themed('theme-color');
                @include media($menu-break) {
                  border-color: transparent transparent themed('theme-color') transparent;
                }
              }
            }
            a:focus {
              border: 2px solid themed('theme-color');
            }
          }
          span:not(.menu-openner),
          a{
            display:block;
            padding:1.5rem 1rem;
            //@include pux-scale-with-min ('font-size', 20px, 16px);
            font-size: 20px;
            font-weight: 700;
            @include flex-block;
            @include align-items(center);
            @include justify-content(center);
            line-height: 23px;
            @include media(xl){
              padding:1.5rem 1.5rem;
            }
            &:focus,
            &:hover{
              text-decoration: none;
            }
            @include media($menu-break) {
              //white-space: nowrap;
              text-align: center;
            }

          }
          span:not(.menu-openner){
            cursor: default;
          }
          &.menu-360{
            display: flex;
            &:before{
              content: "\e900";
              align-self: center;
              width: 30px;
              margin-left: 10px;
              @include media($menu-break){
                width: auto;
                margin-left: 3px;
                display: none;
              }
              @include media(xl){
                display: block;
              }
            }
          }
          ul{
            display: none;
            width:100%;
            @include opacity(1);
            visibility: visible;
            @include transition;
            @include media($menu-break){
              position: absolute;
              top: 100%;
              width:25rem;
              left: 50%;
              right: 50%;
              transform: translateX(-11.5rem);
              visibility: hidden;
              @include opacity(0);
              display: block;
              padding: 1rem 0rem;
            }
            li{
              a{
                @include media($menu-break){
                  padding: 1rem 1.5rem;
                }
              }
            }
          }
          &:hover{
            & > ul {
              visibility: visible;
              @include opacity(1);
            }
          }
          &.open{
            .menu-openner{
              &:after{
                @include rotate(0deg);
              }
            }
            ul{
              display:block;
            }
          }
          &.has-ul{
            @include media($menu-break){
              margin:0 0.5rem 0 0;
            }
            @include media(xl){
              margin:0 1rem;
            }
            span:not(.menu-openner),
            a{
              @include media($menu-break){
                padding:1.5rem 2rem 1.5rem 1rem;
              }
              @include media(xl){
                padding:1.5rem 2rem 1.5rem 1.5rem;
              }
            }
          }
        }
      }
      .menu-openner{
        position: absolute;
        top:0;
        right:0;
        width: 6rem;
        height: 6rem;
        @include media($menu-break){
          position: absolute;
          width: auto;
          height: auto;
          background: transparent;
          bottom: .5rem;
          right: 1rem;
        }
        &:after,
        &:before{
          content:"";
          width:70%;
          height:2px;
          position: absolute;
          left:15%;
          top:50%;
          margin-top:-1px;
          @include media($menu-break){
            content: "\e902";
            background: transparent;
            height: auto;
            width: auto;
            font-size: 6px;
          }
        }
        &:after{
          @include rotate(90deg);
          @include transition;
          @include media($menu-break){
            display: none;
          }
        }
      }
    }
    &-hamburger{
      background-color: $main-color;
      display: flex;
      align-items: center;
      padding: 0.5rem 3rem;
    border-radius: 50px;
    cursor: pointer;
      @include media($menu-break){
        display: none;
      }
      span {
        color: $base-white;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-weight: 700;
      }
      .open-menu{
        width:15px;
        height:15px;
        position: relative;
        display: block;
        margin-right: 1rem;
        span{
          width:10px;
          display: block;
          background: $base-white;
          position: absolute;
          left:0.5rem;
          height: 2px;
          margin-top:-1.5px;
          top:50%;
          border-radius: 50px;
          @include transition;
          &:before,
          &:after{
            content:"";
            width: 10px;
            background-color: $base-white;
            border-radius: 50px;
            left:0;
            height:2px;
            position: absolute;
            @include transition;
          }
          &:before{
            top:-4px;
          }
          &:after{
            bottom:-4px;
          }
        }
      }
    }
    &.open{
      .header-hamburger{
        .open-menu{
          span{
            background: transparent;
            &:before{
              top:0;
              @include rotate(45deg);
            }
            &:after{
              top:0;
              @include rotate(-45deg);
            }
          }
        }
      }
      .header-menu{
        display: block;
        @include media($menu-break) {
          display: flex;
        }
      }
    }
  }
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}

footer{
  background-color: $footer-color;
  color: $base-white;
  @include pux-scale-with-min('font-size', 16px, 15px);
  ul{
    text-align: left;
  }
  .footer{
    @include flex-block;
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    padding: 5rem 0 0 0;
    a{
      color: $base-white;
    }
    h4 {
      @include pux-scale-with-min('font-size', 18px, 16px);
    }
    &-column{
      width: 100%;
      @include pux-scale-with-min("line-height", $line-height-perex, $min-line-height-perex);
      padding-bottom: 5rem;
      text-align: center;
      @include media(sm){
        text-align: left;
        flex: 0 0 33.3333%;
        width: 33.3333%;
      }
      @include media(lg){
        text-align: left;
        padding-bottom: 0;
        flex: 0 0 20%;
        width: 20%;
      }
    }
    .footer-logo{
      display: flex;
      justify-content: center;
      @include media(sm) {
        width: 50%;
        flex: 0 0 50%;
        margin-top: 4rem;
        text-align: left;
        align-items: flex-start;
        justify-content: flex-start;
      }

      @include media(lg) {
        justify-content: flex-end;
        margin-top: 0;
        flex: 0 0 30%;
        width: 30%;
      }
      a {

        &:hover,
        &:focus {
          text-decoration: none;
        }
        &:first-child {
          display: flex;
        align-items: flex-end;
          min-width: 27rem;
          @include media(lg) {
            min-width: 22rem;
          }
        }
        &:last-child {
          @include pux-scale-with-min('font-size', 16px, 10px);
          line-height: 1.2;
          text-transform: uppercase;
          max-width: 100px;
          margin-left: 3rem;
          text-align: left;
          span {
            font-weight: 700;
          }
          @include media(lg) {
            margin-left: 3rem;

            max-width: 120px;
          }
        }
        img {
          max-height: 8rem;
          @include media(md) {
            max-height: 6.5rem;
            max-width: 22rem;
            margin-top: 1rem;
          }
        }
      }
    }
  }
  .footer-copyright {
    text-align: center;
    padding: 0 0 5rem 0;
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    @include media(sm) {
      padding: 3rem 0;
      justify-content: space-between;
      text-align: left;
      flex-direction: row;
    }
    img {
      width: 130px;
      margin: 3rem 0 6rem 0;
      @include media(sm) {
        margin: 3rem 0 0 0;
      }

    }
  }
}

.is-xxl{
    display:none;
    @include media(xxl){
        display:block;
    }
}


.intro-container{
  position: relative;
  height: 100%;
  .bgr-image{
    height: 100%;
    width: 100%;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    position: relative;
    &:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 75vh;
      background: #000;
      @include opacity(0.15);
    }

  }
  .pux-container{
    position: relative;
    .intro-title{
      margin: 0;
      padding: 1.5rem 3rem;
      @include media(md){
        left: 4.5rem;
      }
    }
  }
}



.founder-wrapper{
  position: absolute;
  bottom: -4rem;
  z-index: 5;
  left: 0;
  width: 100%;
  .pux-container{
    text-align: right;
    img{
      max-width: 15rem;
    }
  }
}

.breadcrumbs{
  @include flex-block;
  @include align-items(center);
  @include justify-content(center);
  .home{
    @include flex-block;
    @include align-items(center);
    font-size: 0;
    &:after{
      content: "\e90d";
      @include pux-scale-with-min("font-size", 15px, 14px);
      display: block;
    }
    &:hover,
    &:focus{
      text-decoration: none;
      &:after{
        text-decoration: none;
      }
    }
  }
  padding-top: 1rem;
  .breadcrumbs-separator:after{
    position: relative;
    top: 0px;
    display: inline-block;
    content: "\e902";
    vertical-align: middle;
    @include transform(scale(0.25) rotate(270deg));
    margin: auto .5rem;
  }
}

.header-helper{
  height: 8rem;
  @include media($menu-break){
    height: 7rem;
  }
}

.header-intro{
  padding-top: 1rem;
  padding-bottom: 4rem;
  text-align: center;
  position: relative;
  .bgr-image{
    position: absolute;
    top:0;
    left:0;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
  }
  h1{
    font-weight: 600;
    padding: 2rem 0 2rem 0;
    margin:0;
  }
  &-bread,
  &-title,
  &-subtitle{
    position: relative;
    z-index: 3;
  }
  &-title{
    .h1{
      padding: 0;
      margin: 3rem 0 1rem;
    }
  }
  &-subtitle{
    font-weight: 700;
    @include pux-scale-with-min("font-size", 13px, 13px);
    @include flex-block;
    @include align-items(center);
    @include justify-content(center);
    &:before{
      content: "\e90e";
      margin-right: 1rem;
    }
  }
}

.text-container{
  padding: 5rem 0;
  margin: auto;
  max-width: 900px;
}


.row{
  .col-xs-12.col-md-6.col-lg-4.preview{
    padding: 7.5px;
    a:focus,
    a:hover{
      .detail-photo{
        &-bgr{
          @include transform(scale(1.2));
        }
      }
    }
  }
}

.contact-container{
  a{
    font-weight: 700;
  }
}

.map-container{
  height: 45rem;
  @include media(md){
    height: 100%;
  }
}

.text-and-submit{
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .personal-detail-text{
    width: 100%;
    @include media(lg){
      width: 50%;
    }
  }
  .btn{
    margin: 2rem auto;
    @include media(lg){
      margin: auto 0;
    }
  }
}

.size-switch{
  cursor: pointer;
  position: fixed;
  top: 20vh;
  right: 0;
  text-align: center;
  @include transition;
  z-index: 5;
  display: none;
  @include media($menu-break){
    display: block;
  }
  .small-switch,
  .big-switch{
    border: none;
    @include transition;
    text-align: center;
    background-color: #003e69;
    color: $base-white;
    padding: 1rem 2.5rem 1rem 1.5rem;
    text-transform: uppercase;
    @include pux-static-size("height",50px);
    @include flex-block-important;
    @include align-items(center);
    @include justify-content(center);

    @include transform(rotate(-90deg) translateY(-100%));
    transform-origin: top right;
    &.active {
      display: none!important;
    }

    &:hover {
      background-color: lighten(#003e69, 10);
    }

    &:focus {
      background-color: lighten(#003e69, 10);
      border: 2px solid lighten(#003e69, 30);
    }
  }
  .small-switch{
    //@include pux-scale-with-min("font-size", $font-size-small, $min-font-size-small);
    border-radius: 10px 10px 0 0;
    box-shadow:  1px 1px 6px rgba(76, 76, 76, 0.3);
    position: relative;
    /*&:hover, &:focus{
      &:after{
        @include transform(translateX(-4rem));
      }
    }*/
    &:after{
      content:"\e924";
      @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
      line-height:1;
      display: block;
      @include rotate(90deg);
      padding-bottom: 20px;
    }
    /*&:after{
      content: "Zmenšit písmo";
      @include flex-block;
      @include align-items(center);
      @include justify-content(center);
      font-family: $font-family!important;
      position: absolute;
      right: 0;
      width: 25rem;
      z-index: -1;
      color: $base-black;
      background-color: rgba(255, 255, 255, 0.603);
      @include media(md){
        width: 15rem;
      }
      height: 100%;
      @include pux-scale-with-min("font-size", $font-size-small, $min-font-size-small);
      top: 0;
      @include transition(400ms);
      @include transform(translateX(1000px));
    }*/
  }
  .big-switch{
    border-radius: 10px 10px 0 0;
    box-shadow:  1px 1px 6px rgba(76, 76, 76, 0.3);
    //font-weight: 700;
    position: relative;
    /*&:hover, &:focus{
      &:after{
        @include transform(translateX(-4rem));
      }
    }*/
    &:after{
      @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
      content:"\e923";
      line-height:1;
      display: block;
      @include rotate(90deg);
      padding-bottom: 20px;
    }
    /*&:after{
      @include flex-block;
      @include align-items(center);
      @include justify-content(center);
      content: "Zvětšit písmo";
      font-family: $font-family!important;
      position: absolute;
      right: 0;
      width: 30rem;
      color: $base-black;
      background-color: rgba(255, 255, 255, 0.603);
      z-index: -1;
      @include media(md){
        width: 20rem;
      }
      height: 100%;
      line-height: 100%;
      @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
      font-weight: 700;
      top: 0;
      @include transition(400ms);
      @include transform(translateX(1000px));
    }*/
  }
}

.floating-label-text{
  height: 115px!important;
  @include media(md){
    height: 150px!important;
  }
}

.floating-label-text,
.floating-label {
    $input-height: 60px;
    $input-padding: 0;
    position: relative;
    width: 100%;
    height: 60px;
    @include transition;
    & > .placeholder {
      opacity: 1;
      pointer-events: none;
      line-height: $input-height;
      transform: translate3d(0,-100%,0);
      padding: $input-padding;
      display: inline-block;
      position: relative;
      z-index: 2;
      margin: 0;
      font-size: 30px;
      &.active {
        opacity: 0;
      }
    }
    & > label {
      position: absolute;
      top: 5px;
      left: 15px;
      display: inline-block;
      transition: all .3s ease;
      opacity: 1;
      z-index:11;
      padding: $input-padding;

      &.is-visible {
        transform: translate3d(0,-15px,0);
        opacity: 1;
        font-size:14px;

      }
    }
    & > input {
      position: relative;
      z-index: 1;
      float: left;
      width: 100%;
      height: $input-height;
      clear: both;
      margin-bottom: 1rem;
      padding: $input-padding;
      background-color: transparent;
    }
    &.active{
        label{
            transform: translate3d(0,-8px,0);
            opacity: 1;
            font-size:10px;
            left:15px;
            @include media(md){
              transform: translate3d(0,-10px,0);
            }
        }
    }
}

.is-visible{
  input{
    padding-top: 2.5rem!important;
    padding-left: 15px!important;
    @include media(md){
      padding-top: 1.25rem!important;
    }
  }
  textarea{
    padding-top: 3rem!important;
    padding-left: 15px;
    @include media(md){
      padding-top: 2rem!important;
    }
  }
}

.select-box{
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem auto 3rem;
}

.javascript-hide{
  display: none;
}

.pux-infinite-repeater-options-inner-load-more{
  text-align: center;
}

.mobile-name{
  display: block !important;
  text-align: center;
  @include media($menu-break) {
    display: none !important;
  }
}

.desktop-name{
  display: none !important;
  @include media($menu-break) {
    display: block !important;
  }
}

header.scrolled .header-menu ul li.active:before,
header.scrolled .header-menu ul li a:hover:before{
  bottom:-4px;
}

.dots-wrapper .slick-dots{
  justify-content: center;
}
