
@import "../_mixins/pux/breakpoints.scss";
@import "../_mixins/pux/css-rules.scss";
@import "../_mixins/pux/scale.scss";
@import "../_variables.scss";

$slider-header-height: 8rem;
$slider-content-center: false;
$slider-color: #fff;
$slider-max-width: 75rem;
$slider-full-window: false;
$slider-height: 60rem;

$slider-arrow: true;
$slider-arrow-width: 4rem;
$slider-arrow-height: $slider-arrow-width;
$slider-arrow-background: transparent;
$slider-arrow-radius: 50%;
$slider-arrow-border: none;
$slider-arrow-left: 2rem;
$slider-arrow-right: 2rem;
$slider-arrow-before-size: 20px;
$slider-arrow-prev-code: "\e901";
$slider-arrow-next-code: "\e901";

$slider-dot: true;
$slider-dot-width: 30px;
$slider-dot-height: $slider-dot-width;
$slider-dot-background: #fff;
$slider-dot-active: #fff;
$slider-dot-border: none;
$slider-dot-radius: 50%;
$slider-dot-padding: 0 2rem 0 0;
$slider-dot-bottom: 8rem;


@mixin slick-arrows($arrow-width: $slider-arrow-width, $arrow-height: $slider-arrow-width,$arrow-background: $slider-arrow-background,$arrow-radius: $slider-arrow-radius,$arrow-border: $slider-arrow-border,$arrow-left: $slider-arrow-left,$arrow-right: $slider-arrow-right,$arrow-before-size: $slider-arrow-before-size,$arrow-prev-code: $slider-arrow-prev-code,$arrow-next-code: $slider-arrow-next-code){
  .slick-arrow{
    position:absolute;
    top:50%;
    margin-top: -($arrow-width/2);
    width: $arrow-width;
    height: $arrow-height;
    @include border-radius($arrow-radius);
    border: $arrow-border;
    font-size:0;
    background: $arrow-background;
    outline: 0;
    z-index: 22;
    cursor: pointer;
    &:before{
      font-size: $arrow-before-size;
    }
    &.slick-prev{
      left: $arrow-left;
      &:before{
        content: $arrow-prev-code;
        display: block;
        @include opacity(0.5);
        @include rotate(90deg);
      }
    }
    &.slick-next{
      right: $arrow-right;
      &:before{
        content: $arrow-next-code;
        display: block;
        @include rotate(-90deg);
        @include opacity(0.5);
      }
    }
  }
}

@mixin slick-arrows-size($arrow-width, $arrow-height){
  .slick-arrow{
    width: $arrow-width;
    height: $arrow-height;
  }
}

@mixin slick-arrows-position($arrow-left, $arrow-right){
  .slick-arrow{
    &.slick-prev{
      left: $arrow-left;
    }
    &.slick-next{
      right:  $arrow-right;
    }
  }
}

@mixin slick-dost($dot-bottom: $slider-dot-bottom, $dot-padding: $slider-dot-padding, $dot-width: $slider-dot-width, $dot-height: $slider-dot-height, $dot-border: $slider-dot-border, $dot-radius: $slider-dot-radius, $dot-background: $slider-dot-background, $dot-active: $slider-dot-active){
  .slick-dots{
    @include flex-block;
    @include align-items(flex-end);
    left:0;
    width:100%;
    max-width: 172rem;
    margin:auto;
    bottom: 0;
    padding: 0 0 6rem;
    top: auto!important;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
    li{
      margin:0;
      padding: $dot-padding;
      &:before{
        display:none;
      }
      &:last-child{
        padding:0;
      }
      button{
        font-size: 0;
        outline: 0;
        @include pux-static-size("width", $dot-width);
        @include pux-static-size("height", $dot-height);
        border: $dot-border;
        background: $dot-background;
        @include border-radius($dot-radius);
        padding: 0;
        @include flex-block;
        @include align-items(center);
        @include justify-content(center);
        @include opacity(0.3);
        @include transition;
        cursor: pointer;
        &:before{
          content:"";
          @include pux-static-size("width", 10px);
          @include pux-static-size("height", 10px);
          background: $second-color;
          @include border-radius(50%);
        }
        &:hover{
          @include opacity(1);
          background: $dot-background;
        }
      }
      &.slick-active{
        button{
          background: $dot-active;
          @include opacity(1);
        }
      }
    }
  }
}

.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
}
.slider-init{
  .main-slider{
    width: 100%;
    background-repeat: no-repeat !important;
    display: flex;
    position: relative;
    @include background-size(cover);
    &:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#2e2e2e,0.3);
      @include media(md){
        background-color: transparent;
        background-image: linear-gradient(90deg, rgba(0,0,0,0.5) 40%, rgba(0,0,0,0) 100%);
      }
    }
    .pux-container {
      width: 100%;
    }
    &-inner{
      width:100%;
      padding: 0 0 10rem 0;
      @if $slider-full-window{
        @include calc("min-height", "100vh - "+ $slider-header-height);
      } @else {
         min-height: $slider-height;
         max-height: calc(100vh - $slider-header-height);
      }
      @include flex-block;
      @include flex-direction(column);
      @include align-items(flex-start);
      @include justify-content(center);
      position: relative;
      z-index: 2;
      &-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: $slider-max-width;
        @if $slider-content-center{
          text-align: center;
        }
        &-text{
          color: $slider-color;
          h1,
          h2{
            color: $slider-color;
            padding: 5rem 0 3rem;
          }
        }
        &-btn{
          padding: 5rem 0 2rem 0;
        }
      }
    }
    &.top-center{
      background-position: top center !important;
    }
    &.top-left{
      background-position: top left !important;
    }
    &.top-right{
      background-position: top right !important;
    }
    &.center-center{
      background-position: center center !important;
    }
    &.bottom-center{
      background-position: bottom center !important;
    }
    &.bottom-left{
      background-position: bottom left !important;
    }
    &.bottom-right{
      background-position: bottom right !important;
    }
  }
  @if $slider-arrow{
    @include slick-arrows;
  }
  @if $slider-dot{
    @include slick-dost;
  }
}

.slider-wrapper{
  position: relative;
  display: none;
  @include media(md) {
    display: block;
  }
}

.slick-track{
  position: relative;
}

.dots-wrapper{
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  @include slick-dost;
}

.slider-placeholder{
  width: 15rem;
}

.slider-init{
  height: 100%;
  .slick-list{
    height: 100%;
    .slick-track{
      height: 100%;
    }
  }
}

.main-slider-inner-content-btn{
  .btn-primary{
    white-space: normal;
    padding: 2rem 3rem;
  }
}
